import React from 'react'
import { Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { isMobile } from 'react-device-detect'

const Botao = ({ className, onClick, children, variant = 'default', disabled = false }) => {
  const theme = useTheme()

  const variantes = {
    default: {
      backgroundColor: theme.palette.custom.cor_1,
      color: theme.palette.custom.cor_2,
      border: `2px solid ${theme.palette.custom.cor_3}`,
      fontSize: isMobile ? '1.7rem' : 'medium',
    },
    default_negativo: {
      backgroundColor: theme.palette.custom.cor_4,
      color: theme.palette.custom.cor_2,
      border: `2px solid ${theme.palette.custom.cor_3}`,
      fontSize: isMobile ? '1.7rem' : 'medium',
    },
    escuro: {
      backgroundColor: theme.palette.custom.cor_5,
      color: theme.palette.custom.cor_4,
      border: `2px solid ${theme.palette.custom.cor_3}`,
      fontSize: isMobile ? '1.7rem' : 'medium',
    },
  }

  return (
    <Button
      variant='contained'
      className={className}
      onClick={onClick}
      disabled={disabled}
      sx={{
        ...variantes[variant],
        padding: '0.3rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        borderRadius: '0.5rem',
      }}
    >
      {children}
    </Button>
  )
}

export default Botao