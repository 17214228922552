import { useState } from 'react'
import { Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material'
import { PiDot } from 'react-icons/pi'

const ModalCadastro = ({ setModalCadastro, funcLogin }) => {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')
  const [confirmaSenha, setConfirmaSenha] = useState('')
  const [erros, setErros] = useState([])
  const [logando, setLogando] = useState(false)

  const cadastrar = async () => {
    try {
      const response = await fetch('https://transcritor-ia.com:8086/api/usuario/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username,
          email,
          password1: senha,
          password2: confirmaSenha,
        }),
      })

      if (response.ok) {
        setLogando(true)
        funcLogin(username, senha)
      } else {
        const resposta = await response.json()
        setErros([resposta.detail])
      }
    } catch (error) {
      console.error(error)
      setErros(['Erro inesperado. Tente novamente mais tarde.'])
    }
  }

  const validarSenha = () =>
    senha.length > 8 && /\d/.test(senha) && senha === confirmaSenha

  return (
    <Modal open onClose={() => setModalCadastro(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
          Criar Conta
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Nome de Usuário"
            variant="outlined"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="off"
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
          />
          <TextField
            label="Senha"
            type="password"
            variant="outlined"
            fullWidth
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            autoComplete="new-password"
          />
          <TextField
            label="Confirmar Senha"
            type="password"
            variant="outlined"
            fullWidth
            value={confirmaSenha}
            onChange={(e) => setConfirmaSenha(e.target.value)}
            autoComplete="new-password"
          />
          {erros.length > 0 && (
            <Box>
              {erros.map((erro, index) => (
                <Typography key={index} color="error" variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                  <PiDot color="red" size={14} />
                  {erro}
                </Typography>
              ))}
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={cadastrar}
            disabled={!validarSenha() || !username || !email}
          >
            {logando ? <CircularProgress size={24} color="inherit" /> : 'Cadastrar-se'}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalCadastro