import { useState } from 'react'
import { PiDot } from 'react-icons/pi'

const NovoTipoLivro = ({ setModal, receberEscreventes }) => {
    const token = localStorage.getItem('key')
    const [shake, setShake] = useState(false)
    const [erros, setErros] = useState([])
    const [dados, setDados] = useState({
        nome: '',
        descricao: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setDados((prevDados) => ({
            ...prevDados,
            [name]: value,
        }))
    }

    const cadastrar = async (dados) => {
        try {
            const response = await fetch('https://transcritor-ia.com:8086/api/tipo-documento/', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify(dados),
            })

            if (response.ok) {
                receberEscreventes()
                setModal(false)
            } else {
                const resposta = await response.json()
                const mensagensErro = []

                if (resposta.detail) {
                    mensagensErro.push(resposta.detail)
                } else {
                    for (const key in resposta) {
                        if (Array.isArray(resposta[key])) {
                            mensagensErro.push(...resposta[key])
                        } else {
                            mensagensErro.push(resposta[key])
                        }
                    }
                }

                setErros(mensagensErro)
                setShake(true)
                setTimeout(() => setShake(false), 500)
            }
        } catch (error) {
            console.error('Erro ao cadastrar escrevente:', error)
            setErros(['Erro inesperado ao cadastrar. Tente novamente mais tarde.'])
        }
    }

    return (
        <main className="absolute h-full w-full flex items-center justify-center z-50">
            <div onClick={() => setModal(false)} className="fundo-desfocado"></div>
            <div className="flex flex-col items-center rounded-lg sombra bg-white min-w-[40%] max-w-[70%] max-h-[95%]  overflow-auto z-20">
                <div className="flex items-center justify-center mb-5 border-b-2 border-black w-full">
                    <h1 className="mt-7 mb-3 text-[40px] font-semibold">Adicionar novo tipo</h1>
                </div>

                <div className="flex flex-col w-[90%]">
                    <h1>Nome</h1>
                    <input
                        name="nome"
                        value={dados.nome}
                        onChange={handleChange}
                        type="text"
                        className="input_2"
                    />

                    <h1>Descricao</h1>
                    <input
                        name="descricao"
                        value={dados.descricao}
                        onChange={handleChange}
                        type="text"
                        className="input_2"
                    />

                    {erros.length > 0 && (
                        <div className="mt-2">
                            {erros.map((erro, index) => (
                                <div key={index} className="flex items-center text-red-500 text-xs">
                                    <PiDot color="red" className="mr-1" />
                                    {erro}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="flex justify-around w-[95%] mt-5">
                    {dados.nome !== '' ? (
                        <div
                            className={`relative flex items-center justify-center ${shake ? 'shake-animation' : ''} mt-3`}
                        >
                            <button onClick={() => cadastrar(dados)} className="botao_1 mb-10">
                                <h1 className="text-2xl p-2">Cadastrar</h1>
                            </button>
                        </div>
                    ) : (
                        <div className={`relative flex items-center justify-center mt-3`}>
                            <button disabled className="botao_1 mb-10">
                                <h1 className="text-2xl text-gray-500 p-2">Cadastrar</h1>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </main>
    )
}

export default NovoTipoLivro