export default class Area {
    constructor(superior, inferior, focado, id_regiao, id_imagem, numero, numero_front) {
        this.superior = superior
        this.inferior = inferior
        this.focado = focado
        this.id_regiao = id_regiao
        this.id_imagem = id_imagem
        this.numero = numero
        this.numero_front = numero_front
    }

    verificarPontos(x, y) {
        return x >= this.superior.x && x <= this.inferior.x &&
               y >= this.superior.y && y <= this.inferior.y
    }

    contemLinha(points) {
        if (!Array.isArray(points) || points.length !== 4) {
            throw new TypeError('points must be an array of length 4')
        }
        const [x1, y1, x2, y2] = points
        return this.verificarPontos(x1, y1) && this.verificarPontos(x2, y2)
    }

    limitarPontos(points) {
        const [x1, y1, x2, y2] = points
        const novoX1 = Math.max(this.superior.x, Math.min(x1, this.inferior.x))
        const novoY1 = Math.max(this.superior.y, Math.min(y1, this.inferior.y))
        const novoX2 = Math.max(this.superior.x, Math.min(x2, this.inferior.x))
        const novoY2 = Math.max(this.superior.y, Math.min(y2, this.inferior.y))
        return [novoX1, novoY1, novoX2, novoY2]
    }

    atualizarCanto(corner, newCoords) {
        if (corner === 'superior') {
            this.superior = newCoords
        } else if (corner === 'inferior') {
            this.inferior = newCoords
        } else if (corner === 'superiorXInferiorY') {
            this.superior = { x: newCoords.x, y: this.superior.y }
            this.inferior = { x: this.inferior.x, y: newCoords.y }
        } else if (corner === 'inferiorXSuperiorY') {
            this.superior = { x: this.superior.x, y: newCoords.y }
            this.inferior = { x: newCoords.x, y: this.inferior.y }
        }
    }

    static handleSelecionarRegiao(pos, transformCoords, regioes, setRegiaoSelecionada, setCantoSelecionado, setTemp) {
        const { x, y } = transformCoords(pos.x, pos.y)

        regioes.forEach((regiao, index) => {
            const { superior, inferior } = regiao
            const cantos = [
                { name: 'superior', x: superior.x, y: superior.y },
                { name: 'inferior', x: inferior.x, y: inferior.y },
                { name: 'superiorXInferiorY', x: superior.x, y: inferior.y },
                { name: 'inferiorXSuperiorY', x: inferior.x, y: superior.y }
            ]

            cantos.forEach((corner) => {
                if (Math.hypot(corner.x - x, corner.y - y) < 10) {
                    setRegiaoSelecionada(index)
                    setTemp(regiao)
                    setCantoSelecionado(corner.name)
                }
            })
        })
    }

    handleModificacaoRegiao(pos, transformCoords, setRegioes, regiaoSelecionada, cantoSelecionado, atualizarLinhasDentroRegiao) {
        if (regiaoSelecionada != null && cantoSelecionado) {
            const { x, y } = transformCoords(pos.x, pos.y)

            setRegioes((prevRegioes) =>
                prevRegioes.map((regiao, index) => {
                    if (index !== regiaoSelecionada) return regiao

                    const novoRegiao = new Area(regiao.superior, regiao.inferior, regiao.focado, regiao.id_regiao, regiao.id_imagem, regiao.numero)
                    novoRegiao.atualizarCanto(cantoSelecionado, { x, y })

                    atualizarLinhasDentroRegiao(index, novoRegiao)
                    return novoRegiao
                })
            )
        }
    }

    static handleCriacaoAreaStart(pos, transformCoords, setAreaAtual) {
        const { x, y } = transformCoords(pos.x, pos.y)
        setAreaAtual([{ x, y }, { x, y }])
    }

    static handleCriacaoAreaMove(pos, transformCoords, areaAtual, setAreaAtual) {
        if (areaAtual.length === 0) return

        const { x, y } = transformCoords(pos.x, pos.y)
        setAreaAtual([areaAtual[0], { x, y }])
    }

    static handleCriacaoAreaFim(areaAtual, setAreaAtual, regioes, setRegioes, enviarRegiaoApi, regiaoSelecionada) {
        if (areaAtual.length === 2) {
            const novaArea = new Area(areaAtual[0], areaAtual[1], false, regioes.length)
            setRegioes((prevRegioes) => [...prevRegioes, novaArea])
            enviarRegiaoApi(areaAtual)
        }
        setAreaAtual([])
    }
}