import React, { useRef, useEffect, useState, useCallback } from 'react'
import Linha from '../classes/linha'
import Area from '../classes/area'
import Validacao from '../interface/modais/modal_validacao'
import ListRegiao from '../interface/regiao'
import { useAtalho } from '../util/atalho'
import { useAtalhoCombo } from '../util/atalho_combo_ctrl'
import { Stage, Layer, Image, Rect, Line, Circle, Text, Group } from 'react-konva'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { handleMouseDown, handleMouseMove, handleMouseUp } from '../util/mouseHandlers'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { Box, CircularProgress, Drawer, IconButton, InputAdornment, Modal, Skeleton, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import { FaPenNib } from 'react-icons/fa'
import { HiCursorClick } from 'react-icons/hi'
import { PiSelectionPlusBold, PiTrash } from 'react-icons/pi'
import Botao from '../interface/botão'
import BarraProgresso from '../interface/barra_progresso'
import { isBrowser } from 'react-device-detect'

// tela de transcrição fica dividida em varios arquivos para facilitar a leitura (ficava uns 3k de linha quando era tudo junto)
// outros são, mouseHandlers que tem as funcionalidade de mouse
// tem os arquivos das classes que controlam as areas e linhas em classes/
// o menu lateral direito fica em interface/regiao e interface/linha

const Transcricao = () => {
    const MAX_ZOOM = 5
    const MIN_ZOOM = 0.15
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()
    const {search} = location.state || {}
    const searchParams = new URLSearchParams(location.search)
    const user = JSON.parse(localStorage.getItem("user"))
    const id_linha = parseInt(searchParams.get('linha'))
    const inputbusca = search
    const { id, nome, dono, numeroImg, total, id_imagem } = useParams()
    const token = localStorage.getItem('key')
    const stageRef = useRef(null)
    const imagemRef = useRef(null)
    const [numero, setNumero] = useState()
    const [nivelZoom] = useState(1)
    const [modo, setModo] = useState("")
    const undoStack = useRef([])
    const redoStack = useRef([])
    const [temp, setTemp] = useState(null)
    const [view, setView] = useState(true)
    const [linhas, setLinhas] = useState([])
    const [imagem, setImagem] = useState(null)
    const [regioes, setRegioes] = useState([])
    const [linkImg, setLinkImg] = useState(null)
    const [movendo, setMovendo] = useState(false)
    const [areaAtual, setAreaAtual] = useState([])
    const [linhaAtual, setLinhaAtual] = useState([])
    const [dadosValidar, setDadosValidar] = useState({ numeroLinha: '', numeroPagina: '', numeroRegiao: '' })
    const [hoveredPonto, setHoveredPonto] = useState({ id_regiao: null, ponto: null })
    const [modalValidar, setModalValidar] = useState(false)
    const [stage, setStage] = useState({ scale: .25, x: 0, y: 0 })
    const [cantoSelecionado, setCantoSelecionado] = useState(null)
    const [linhaSelecionada, setLinhaSelecionada] = useState(null)
    const [pontoSelecionado, setPontoSelecionado] = useState(null)
    const [lastMousePosition, setLastMousePosition] = useState(null)
    const [regiaoSelecionada, setRegiaoSelecionada] = useState(null)
    const [linhaFocadaId, setLinhaFocadaId] = useState(null)
    const linhaFocadaRef = useRef(null)
    const [menuAberto, setMenuAberto] = useState(true)
    const [hoveredLinha, setHoveredLinha] = useState(null)
    const [linhasAtualizadas, setLinhasAtualizadas] = useState([])
    const [loading, setLoading] = useState(true)
    const [paginaInput, setPaginaInput] = useState(numeroImg)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [selectedLinha, setSelectedLinha] = useState(null)
    const [lastConfirmedLinhaId, setLastConfirmedLinhaId] = useState(null)
    const isModoAtivo = (modoAtual) => modo === modoAtual
    const [transcrevendoModal, setTranscrevendoModal] = useState(false)
    const [problemModal, setProblemModal] = useState(false)
    const [infoTreino, setInfoTreino] = useState([])
    const [treinando, setTreinando] = useState(false)

    // Função para exibir o modal de confirmação
    const handleConfirmModal = (linha) => {
        if (linha.id_linha !== lastConfirmedLinhaId) {
            setSelectedLinha(linha)
            setShowConfirmModal(true)
        } else {
            setLinhaSelecionada([linha, linhas.indexOf(linha)])
            setPontoSelecionado(linha.detectarProximidade(linha.pontos[0], linha.pontos[1]))
            setTemp(linha.pontos)
        }
    }

    /// funcionalidades de desfazer e refazer
    const addAcaoPilha = (action) => {
        undoStack.current.push(action)
        redoStack.current = []
    }

    const undo = async () => {
        if (undoStack.current.length === 0) return
    
        const acaoRecente = undoStack.current.pop()   
    
        try {
            switch (acaoRecente.tipo) {
                case 'createLinha':
                    await deletarLinhaApi(acaoRecente.data.id_linha, false)
                    redoStack.current.push(acaoRecente)
                    break
                case 'deleteLinha':
                    const linhaCriada = await enviarLinhaParaAPI({
                        id_regiao: acaoRecente.data[0].id_regiao,
                        transcricao: "",
                        pontos: [
                            [acaoRecente.data[0].pontos[0], acaoRecente.data[0].pontos[1]],
                            [acaoRecente.data[0].pontos[2], acaoRecente.data[0].pontos[3]]
                        ],
                        numero: acaoRecente.data[0].numero || 1
                    }, false)
                    acaoRecente.data = linhaCriada
                    redoStack.current.push(acaoRecente)
                    break
                case 'updateLinha':
                    await editarLinha(acaoRecente.id_linha, acaoRecente.pontosVelhos, false)
                    break
                case 'createRegiao':
                    await deletarRegiaoApi(acaoRecente.data[0].id_regiao, false)
                    redoStack.current.push(acaoRecente)
                    break
                case 'deleteRegiao':
                    const areaCriada = await enviarRegiaoApi({
                        id_imagem: id_imagem,
                        pontos: [
                            [acaoRecente.data[0].superior.x, acaoRecente.data[0].superior.y],
                            [acaoRecente.data[0].inferior.x, acaoRecente.data[0].inferior.y]
                        ]
                    } ,false)
                    acaoRecente.data = areaCriada
                    redoStack.current.push(acaoRecente)
                    break
                case 'updateRegiao':
                    await editarRegiao({
                        index: acaoRecente.data[0].pontosVelhos.index,
                        superior: acaoRecente.data[0].pontosVelhos.superior,
                        inferior: acaoRecente.data[0].pontosVelhos.inferior,
                        id_regiao: acaoRecente.data[0].pontosVelhos.id_regiao,
                    }, false)
                    redoStack.current.push(acaoRecente)
                    break
                default:
                    break
            }
        } catch (error) {
            console.error('Erro ao executar undo:', error)
            redoStack.current.push(acaoRecente) // recoloca a ação na pilha de redo em caso de erro
        }
    }
    
    const redo = async () => {
        if (redoStack.current.length === 0) return
        const acaoRecente = redoStack.current.pop()
    
        try {
            switch (acaoRecente.tipo) {
                case 'createLinha':
                    const linhaCriada = await enviarLinhaParaAPI({
                        id_regiao: acaoRecente.data[0].id_regiao,
                        transcricao: "",
                        pontos: [
                            [acaoRecente.data[0].pontos[0][0], acaoRecente.data[0].pontos[0][1]],
                            [acaoRecente.data[0].pontos[1][0], acaoRecente.data[0].pontos[1][1]]
                        ],
                        numero: acaoRecente.data[0].numero || 1
                    }, false)
                    acaoRecente.data = linhaCriada
                    redoStack.current.push(acaoRecente)
                    break
                case 'deleteLinha':
                    await deletarLinhaApi(acaoRecente.data[0].id_linha, false)
                    undoStack.current.push(acaoRecente)
                    break
                case 'updateLinha':
                    await editarLinha(acaoRecente.id_linha, acaoRecente.novosPontos, false)
                    undoStack.current.push(acaoRecente)
                    break
                case 'createRegiao':
                    const regiaoCriada = await enviarRegiaoApi({
                        id_imagem: id_imagem,
                        pontos: [
                            [acaoRecente.data[0].pontos[0][0], acaoRecente.data[0].pontos[0][1]],
                            [acaoRecente.data[0].pontos[1][0], acaoRecente.data[0].pontos[1][1]]
                        ]
                    }, false)
                    acaoRecente.data = regiaoCriada
                    undoStack.current.push(acaoRecente)
                    break
                case 'deleteRegiao':
                    await deletarRegiaoApi(acaoRecente.data[0].id_regiao, false)
                    setRegioes((prev) => prev.filter((regiao) => regiao.id_regiao !== acaoRecente.data.id_regiao))
                    undoStack.current.push(acaoRecente)
                    break
                case 'updateRegiao':
                    await editarRegiao({
                        index: acaoRecente.data[0].novosPontos.index,
                        superior: acaoRecente.data[0].novosPontos.superior,
                        inferior: acaoRecente.data[0].novosPontos.inferior,
                        id_regiao: acaoRecente.data[0].novosPontos.id_regiao,
                    }, false)
                    undoStack.current.push(acaoRecente)
                    break
                default:
                    break
            }
        } catch (error) {
            console.error('Erro ao executar redo:', error)
            redoStack.current.push(acaoRecente) // recoloca a ação na pilha de redo em caso de erro
        }
    }
    
    ///
    useEffect(() => {
        setPaginaInput(numeroImg)
        // eslint-disable-next-line
    }, [numeroImg])

    const handleCrash = () => {
        setProblemModal(true)
        setLinhaSelecionada(null)
        setPontoSelecionado(null)
        setCantoSelecionado(null)        
    }

    const verificarStatus = async () => {
        const statusPublic = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        if (statusPublic.ok) {
            const resposta = await statusPublic.json()
            if(resposta.publico === false || user.name === 'george'){
                setView(false)
            }
            if (resposta.status_transcricao) {
                setTranscrevendoModal(true)
                setLinhaSelecionada(null)
                setPontoSelecionado(null)
                setCantoSelecionado(null)
                setView(true)
            }
            if(resposta.status_treinamento){
                setTreinando(true)
            }else{
                setTreinando(false)
            }

        }
    }

    const getInfoTreino = async () => {
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/info-treinamento/`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        if (response.ok) {
            const resposta = await response.json()
            setInfoTreino(resposta)
        }
    }

    const getDados = async () => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id_imagem}/transcricao/`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
    
            if (response.ok) {
                try {
                    const livroResponse = await fetch(`https://transcritor-ia.com:8086/api/livro/compartilhados/`, {
                        method: 'GET',
                        headers: {
                            'accept': 'application/json',
                            'Authorization': `Token ${token}`
                        }
                    })
                    if (livroResponse.ok) {
                        const respostaShare = await livroResponse.json()
                        respostaShare.map((livroCompartilhado) => {
                            if((parseInt(livroCompartilhado.id_livro) === parseInt(id) && livroCompartilhado.funcao === "visualizador") || user.name === 'george'){
                                setView(false)
                            }
                        })
                    }
                } catch (error) {
                    handleCrash()
                    return
                }
                
                verificarStatus()
                getInfoTreino()
    
                const resposta = await response.json()
    
                try {
                    const imageResponse = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/imagens/`, {
                        method: 'GET',
                        headers: {
                            'accept': 'application/json',
                            'Authorization': `Token ${token}`
                        }
                    })
            
                    if (imageResponse.ok) {
                        const images = await imageResponse.json()
                        images.map((image) => {
                            if (parseInt(image.id_imagem) === parseInt(id_imagem)) {
                                setLinkImg(image.imagem)
                                setNumero(image.numero)
                            }
                        })
                        if(temp === false){
                            navigate('/coleções')
                        }
                    }
                } catch (error) {
                    handleCrash()
                    return
                }
        
                setRegioes([])
                setLinhas([])
        
                const regioesTemp = []
        
                for (let i = 0; i < resposta.length; i++) {
                    const regiao = resposta[i]?.regiao
                    if (regiao && regiao.pontos && regiao.pontos.length >= 2) {
                        regioesTemp.push(new Area(
                            { x: regiao.pontos[0][0], y: regiao.pontos[0][1] },
                            { x: regiao.pontos[1][0], y: regiao.pontos[1][1] },
                            false,
                            regiao.id_regiao,
                            regiao.id_imagem,
                            regiao.numero
                        ))
                    }
        
                    const linhas = resposta[i]?.linhas
                    if (linhas) {
                        for (let j = 0; j < linhas.length; j++) {
                            const linha = linhas[j]
                            if (linha && linha.pontos && linha.pontos.length >= 2) {
                                const novaLinha = new Linha(
                                    [linha.pontos[0][0], linha.pontos[0][1],
                                     linha.pontos[1][0], linha.pontos[1][1]],
                                    linha.transcricao,
                                    false,
                                    linha.id_linha,
                                    linha.id_regiao,
                                    linha.numero,
                                    linha.certificada,
                                    linha.linha
                                )
        
                                setLinhas(prevLinhas => [
                                    ...prevLinhas,
                                    novaLinha
                                ])
                            }
                        }
                    }
                }
        
                regioesTemp.sort((a, b) => {
                    const distanciaA = Math.sqrt(a.superior.x ** 2 + a.superior.y ** 2)
                    const distanciaB = Math.sqrt(b.superior.x ** 2 + b.superior.y ** 2)
                    return distanciaA - distanciaB
                })
                
            
                regioesTemp.forEach((regiao, index) => {
                    regiao.numero_front = index + 1
                })
                
                setRegioes(regioesTemp)
        
                if (linhaFocadaId) {
                    handleFocarLinha(linhaFocadaId)
                }
        
            } else {
                navigate('/coleções')
            }
        } catch (error) {
            handleCrash()
        }
    }

    const getStatus = async () => {
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })

        if (response.ok) {
            const resposta = await response.json()
            if (!resposta.status_transcricao) {
                setView(false)
            } else {
                setView(true)
            }
            return resposta.status_transcricao
        } else {
            navigate('/coleções')
        }
    }

    const checkStatus = async () => {
        let status = await getStatus()
        do{
            await new Promise(resolve => setTimeout(resolve, 5000)) // 5 segundos
            status = await getStatus()
        } while (!status) 
    }  
    
    const renderizarImagem = useCallback( async () => {
        const img = new window.Image()
        img.src = await `https://transcritor-ia.com:8086${linkImg}`

        img.onload = () => {
            setImagem(img)
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [linkImg])

    // um use Effect é para somente quando usar a nevegação interna
    useEffect(() => {
        getDados()
        renderizarImagem()
        checkStatus()
        // eslint-disable-next-line
    }, [location])

    useEffect(() => {
        getDados()
        renderizarImagem()
        checkStatus()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const preventContextMenu = (e) => e.preventDefault()
        document.addEventListener('contextmenu', preventContextMenu)
        return () => document.removeEventListener('contextmenu', preventContextMenu)
    }, [])
    
    // funções de desenho - linhas
    const handleDesenhoStart = (pos) => {
        Linha.handleDesenhoStart(pos, transformCoords, setLinhaAtual)
    }

    const handleDesenho = (pos) => {
        Linha.handleDesenho(pos, transformCoords, linhaAtual, setLinhaAtual)
    }
    
    const handleDesenhoFim = () => {
        Linha.handleDesenhoFim(linhaAtual, linhas, regioes, setLinhas, enviarLinhaParaAPI, setLinhaAtual)
    }  
     
    const handleSelecionarLinha = (pos) => {
        const { x, y } = transformCoords(pos.x, pos.y)
    
        linhas.forEach((linha, index) => {
            if (linha instanceof Linha) {
                const pontoProximo = linha.detectarProximidade(x, y)
                if (pontoProximo !== null) {
                    if (linha.certificada) {
                        handleConfirmModal(linha)
                    } else {
                        setLinhaSelecionada([linha, index])
                        setPontoSelecionado(pontoProximo)
                        setTemp(linha.pontos)
                    }
                }
            }
        })
    }

    // Função para confirmar a edição da linha certificada
    const confirmEditLinha = () => {
        setLinhaSelecionada([selectedLinha, linhas.indexOf(selectedLinha)])
        setPontoSelecionado(selectedLinha.detectarProximidade(selectedLinha.pontos[0], selectedLinha.pontos[1]))
        setTemp(selectedLinha.pontos)
        setLastConfirmedLinhaId(selectedLinha.id_linha)
        setShowConfirmModal(false)
    }

    // Função para cancelar a edição da linha certificada
    const cancelEditLinha = () => {
        setSelectedLinha(null)
        setShowConfirmModal(false)
    }

    const handleModificacaoLinha = (pos) => {
        const { x, y } = transformCoords(pos.x, pos.y)
    
        setLinhas((prevLinhas) =>
            prevLinhas.map((linha, index) => {
                if (index === linhaSelecionada[1]) {
                    const novosPontos =
                        pontoSelecionado === 0
                            ? [x, y, linha.pontos[2], linha.pontos[3]]
                            : [linha.pontos[0], linha.pontos[1], x, y]
    
                    linha.atualizarPontos(novosPontos, regioes)
    
                    
                }
                return linha
            })
        )
    }

    const enviarLinhaParaAPI = async (linha, novoMod = true) => {
        verificarStatus()
        try {
            const response = await fetch('https://transcritor-ia.com:8086/api/livro/linha/', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(linha)
            })
    
            if (response.ok) {
                const linha = await response.json()
                if(novoMod){
                    addAcaoPilha({ tipo: 'createLinha', data: linha })
                }
                getDados()

                return {certificada: linha.certificada, id_linha: linha.id_linha, id_regiao: linha.id_regiao, numero: linha.numero, pontos: [linha.pontos[0][0], linha.pontos[0][1], linha.pontos[1][0], linha.pontos[1][1]], transcricao: linha.transcricao}
            }
        } catch (error) {
            handleCrash()
            console.error('Erro ao criar linha:', error)
        }
    }

    const deletarLinhaApi = async (linhaId, novoMod = true) => {
        verificarStatus()
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/linha/${linhaId}/`, {
                method: 'DELETE',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                },
            })
    
            if (response.ok) {
                if(novoMod){
                    addAcaoPilha({ tipo: 'deleteLinha', data: linhas.filter((linha) => linha.focado) })
                }
                getDados()
            } else {
                console.error(`Erro ao deletar a linha ${linhaId}:`, response.status, await response.text())
            }
        } catch (error) {
            handleCrash()
            console.error(`Erro na requisição de deletar linha ${linhaId}:`, error)
        }
    }

    const editarLinha = async (linhaId, novosPontos, novoMod = true) => {
        try {
            // Atualiza o estado de processando
            setLinhas(prevLinhas => prevLinhas.map(linha => 
                linha.id_linha === linhaId ? { ...linha, processando: true } : linha
            ))
    
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${linhaId}/editar-segmentacao/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pontos: [
                        [novosPontos[0], novosPontos[1]],
                        [novosPontos[2], novosPontos[3]]
                    ],
                    id_linha: linhaId,
                }),
            })
    
            if (response.ok) {
                if (novoMod) {
                    addAcaoPilha({ tipo: 'updateLinha', pontosVelhos: temp, novosPontos, id_linha: linhaId })
                    const resposta = await response.json();
    
                    // formtação da linha para atualizar o estado
                    const novaLinha = new Linha(
                        [resposta.pontos[0][0], resposta.pontos[0][1],
                        resposta.pontos[1][0], resposta.pontos[1][1]],
                        resposta.transcricao,
                        false,
                        resposta.id_linha,
                        resposta.id_regiao,
                        resposta.numero,
                        resposta.certificada,
                        resposta.linha
                    )
    
                    // atualiza apenas a linha específica
                    setLinhas(prevLinhas => prevLinhas.map(linha => 
                        linha.id_linha === resposta.id_linha ? novaLinha : linha
                    ))
                }
    
                // Atualizar o estado do modal de validação
                const linhaEditada = linhas.find(linha => linha.id_linha === linhaId)
                if (linhaEditada) {
                    setDadosValidar({
                        numeroLinha: linhaEditada.numero,
                        numeroPagina: numero,
                        numeroRegiao: linhaEditada.id_regiao
                    })
                }
            } else {
                console.error("Erro ao editar a linha:", response.status, await response.text())
            }
        } catch (error) {
            handleCrash()
            console.error("Erro na requisição de edição da linha:", error)
        } 
    }
    
    // funções de desenho - região
    const handleSelecionarRegiao = (pos) => {
        Area.handleSelecionarRegiao(pos, transformCoords, regioes, setRegiaoSelecionada, setCantoSelecionado, setTemp)
    }
    
    const handleModificacaoRegiao = (pos) => {
        const regiao = regioes[regiaoSelecionada]
        regiao.handleModificacaoRegiao(pos, transformCoords, setRegioes, regiaoSelecionada, cantoSelecionado, atualizarLinhasDentroRegiao)
    }
    
    const atualizarLinhasDentroRegiao = (indexRegiao, regiaoAtualizada) => {
        const regiaoAntiga = regioes[indexRegiao]
        const novasLinhas = []
    
        setLinhas((prevLinhas) =>
            prevLinhas.map((linha) => {
                const estaDentroRegiaoAntiga = regiaoAntiga.contemLinha(linha.pontos)
                const estaDentroRegiaoNova = regiaoAtualizada.contemLinha(linha.pontos)
    
                if (estaDentroRegiaoAntiga || estaDentroRegiaoNova) {
                    const pontosAjustados = regiaoAtualizada.limitarPontos(linha.pontos)
    
                    if (
                        pontosAjustados[0] !== linha.pontos[0] ||
                        pontosAjustados[1] !== linha.pontos[1] ||
                        pontosAjustados[2] !== linha.pontos[2] ||
                        pontosAjustados[3] !== linha.pontos[3]
                    ) {
                        const novaLinha = new Linha(
                            pontosAjustados,
                            linha.transcricao,
                            false,
                            linha.id_linha,
                            linha.id_regiao,
                            linha.numero,
                            linha.certificada,
                            linha.linha
                        )
                        novasLinhas.push(novaLinha)
                        return novaLinha
                    }
                }
                return linha
            })
        )
    
        setLinhasAtualizadas(novasLinhas)
    }
     
    const handleCriacaoAreaStart = (pos) => {
        Area.handleCriacaoAreaStart(pos, transformCoords, setAreaAtual)
    }
    
    const handleCriacaoAreaMove = (pos) => {
        Area.handleCriacaoAreaMove(pos, transformCoords, areaAtual, setAreaAtual)
    }

    const handleCriacaoAreaFim = () => {
        Area.handleCriacaoAreaFim(areaAtual, setAreaAtual, regioes, setRegioes, enviarRegiaoApi, regiaoSelecionada)
    }

    const enviarRegiaoApi = async (regiao, novoMod = true) => {
        let pontos = []
        verificarStatus()

        try{
            pontos = [
                [regiao[0].x, regiao[0].y],
                [regiao[1].x, regiao[1].y]
            ]
        }catch{
            pontos = regiao.pontos
        }
        
        try {
            const response = await fetch('https://transcritor-ia.com:8086/api/livro/regiao/', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({
                    id_imagem: id_imagem,
                    pontos: pontos
                })
            })

            if (response.ok) {
                const regiao = await response.json()
                if(novoMod){
                    addAcaoPilha({ tipo: 'createRegiao', data: [regiao] })
                }
                getDados()
                return [{
                    superior: { x: regiao.pontos[0][0], y: regiao.pontos[0][1] },
                    inferior: { x: regiao.pontos[1][0], y: regiao.pontos[1][1] },
                    id_regiao: regiao.id_regiao,
                    numero: regiao.numero
                }]
            } else {
                console.error('Erro na resposta da API:', await response.text())
            }
        } catch (error) {
            handleCrash()
            console.error('Erro ao atualizar a região:', error)
        }
    }

    const deletarRegiaoApi = async (id_regiao, novoMod = true) => {
      verificarStatus()
      try {
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/regiao/${id_regiao}/`, {
          method: 'DELETE',
          headers: {
            accept: 'application/json',
            Authorization: `Token ${token}`,
          },
        })
        if (response.ok) {
            if(novoMod){
                addAcaoPilha({ tipo: 'deleteRegiao', data: regioes.filter((regiao) => regiao.focado) })
            }
            getDados()
        } else {
          console.error('Erro ao deletar a região:', await response.text())
        }
      } catch (error) {
        handleCrash()
        console.error('Erro na requisição de deleção da região:', error)
      }
    }

    const editarRegiao = async (regiaoSelecionada, novoMod = true) => {
        let regiao = null
        let pontos = []
        
        if (Number.isInteger(regiaoSelecionada)) {
            regiao = regioes[regiaoSelecionada]
            pontos = [
                [regiao.superior.x, regiao.superior.y],
                [regiao.inferior.x, regiao.inferior.y]
            ]
        } else {
            pontos = [
                [regiaoSelecionada.superior.x, regiaoSelecionada.superior.y],
                [regiaoSelecionada.inferior.x, regiaoSelecionada.inferior.y]
            ]
            regiaoSelecionada = regiaoSelecionada.index
            regiao = regioes[regiaoSelecionada]
        }
        verificarStatus()
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/regiao/${regiao.id_regiao}/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({
                    id_imagem: id_imagem,
                    pontos: pontos
                })
            })
    
            if (response.ok) {
                if(novoMod){
                    addAcaoPilha({ tipo: 'updateRegiao', 
                        data: [{
                            pontosVelhos : {
                                index: regiaoSelecionada,
                                superior: temp.superior,
                                inferior: temp.inferior,
                                id_regiao: regiao.id_regiao,
                                numero: regiao.numero
                            },
                            novosPontos: {
                                index: regiaoSelecionada,
                                superior: regiao.superior,
                                inferior: regiao.inferior,
                                id_regiao: regiao.id_regiao,
                                numero: regiao.numero
                            }
                        }]})
                }
                atualizarLinhasDentroRegiao(regiaoSelecionada, regiao)
                const promise = linhasAtualizadas.map((linha) => 
                    editarLinha(linha.id_linha, linha.pontos, false)
                )
                
                Promise.all(promise).then(() => {
                    getDados()
                })
                setTemp(null)
            } else {
                console.error('Erro ao editar a região:', await response.text())
            }
        } catch (error) {
            handleCrash()
            setRegiaoSelecionada(null)
            setCantoSelecionado(null)
            console.error('Erro ao editar a região:', error)
        }
    }  

    // funções utilitarias
    const handleFocarLinha = (id, centraliza = false) => {
        setLinhas((prevLinhas) =>
            prevLinhas.map((linha) => {
                if (linha.id_linha === id) {
                    linha.focado = true
    
                    if (centraliza) {
                        const [x1, y1, x2, y2] = linha.pontos
                        const posX = (x1 + x2) / 2
                        const posY = (y1 + y2) / 2
    
                        const stage = stageRef.current
                        const stageWidth = stage.width()
                        const stageHeight = stage.height()
    
                        const linhaWidth = Math.abs(x2 - x1)
                        const linhaHeight = Math.abs(y2 - y1)
    
                        const scaleX = stageWidth / (linhaWidth * 1.2)
                        const scaleY = stageHeight / (linhaHeight * 1.2)
                        const newScale = Math.min(scaleX, scaleY, MAX_ZOOM)
    
                        const novaPosX = -posX * newScale + stageWidth / 2
                        const novaPosY = -posY * newScale + stageHeight / 2
    
                        setStage({
                            scale: newScale,
                            x: novaPosX,
                            y: novaPosY
                        })
                    }
                    setMenuAberto(false)
                } else {
                    linha.focado = false
                }
                return linha
            })
        )
        setLinhaFocadaId(id)
        
        setRegioes((prevRegioes) =>
            prevRegioes.map((regiao) => {
                regiao.focado = false
                return regiao
            })
        )
    }
    
    const handleFocarLinhaRegiao = (id) => { 
        setRegioes((prevRegioes) =>
            prevRegioes.map((regiao) => {
                if (regiao.id_regiao === id) {
                    regiao.focado = true
                } else {
                    regiao.focado = false
                }
                return regiao
            })
        )
        setLinhaFocadaId(null)
        setLinhas((prevLinhas) =>
            prevLinhas.map((linha) => {
                linha.focado = false
                return linha
            })
        )
    }   
    
    const handleZoom = (e) => {
        e.evt.preventDefault()
        const multiplicadorZoom = 1.7
        const stage = e.target.getStage()
        const zoomAntigo = stage.scaleX()
        const posMouse = {
            x: stage.getPointerPosition().x / zoomAntigo - stage.x() / zoomAntigo,
            y: stage.getPointerPosition().y / zoomAntigo - stage.y() / zoomAntigo
        }

        let novoZoom = e.evt.deltaY < 0 ? zoomAntigo * multiplicadorZoom : zoomAntigo / multiplicadorZoom
        novoZoom = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, novoZoom))

        const novaPosX = (stage.getPointerPosition().x / novoZoom - posMouse.x) * novoZoom
        const novaPosY = (stage.getPointerPosition().y / novoZoom - posMouse.y) * novoZoom

        setStage({
            scale: novoZoom,
            x: novaPosX,
            y: novaPosY
        })
    }

    const transformCoords = (x, y) => {
        const stage = stageRef.current
        const scale = stage.scaleX()
        const stageX = stage.x()
        const stageY = stage.y()

        return {
            x: (x - stageX) / scale,
            y: (y - stageY) / scale
        }
    }
    
    const deletarSelecionados = () => {        
        setLinhas((prevLinhas) => {
            const linhasRestantes = []
            prevLinhas.forEach((linha) => {
                if (linha.focado) {
                    deletarLinhaApi(linha.id_linha)
                } else {
                    linhasRestantes.push(linha)
                }
            })
            return linhasRestantes
        })
    
        setRegioes((prevRegioes) => {
            const regioesRestantes = []
            prevRegioes.forEach((regiao) => {
                if (regiao.focado) {
                    deletarRegiaoApi(regiao.id_regiao)
                } else {
                    regioesRestantes.push(regiao)
                }
            })
            return regioesRestantes
        })
    }

    const validarLinha = (linha, indexLinha, indexRegiao) => {
        setDadosValidar({
            numeroLinha: indexLinha + 1,
            numeroPagina: numero,            
            numeroRegiao: indexRegiao + 1
        })
        setLinhaSelecionada(linha)
        setModalValidar(true)
    }

    const reset = () => {
        setModo('')
        setLinhaFocadaId(null)
        setHoveredPonto({ id_regiao: null, ponto: null })
        setRegioes((prevRegioes) =>
            prevRegioes.map((regiao) => {
                regiao.focado = false
                return regiao
            })
        )
        setLinhas((prevLinhas) =>
            prevLinhas.map((linha) => {
                linha.focado = false
                return linha
            })
        )
    }

    const desenharSegmentacao = (linha) => {
        return (
            <Line
                points={[
                    linha.pontos[0], linha.pontos[1] - 32,
                    linha.pontos[2], linha.pontos[3] - 32,
                    linha.pontos[2], linha.pontos[3] + 16,
                    linha.pontos[0], linha.pontos[1] + 16
                ]}
                closed
                fill="rgba(255, 255, 204, 0.5)"
            />
        )
    }

    const handleCLickLinha = (linha, index, indexLinha) => {
        if (linha.focado) {
            validarLinha(linha, index, indexLinha)
        } else {
            handleFocarLinha(linha.id_linha)
        }
    }

    const regioesOrdenadas = [...regioes].sort((a, b) => a.numero_front - b.numero_front)

    // Componentes de desenho da linha e região (para facilitar a leitura do código)
    const LinhaComponent = ({ linha, handleFocarLinha, hoveredLinha, setHoveredLinha, modo, movendo, index, indexRegiao, theme }) => (
        <React.Fragment>
            {hoveredLinha === `${linha.id_linha}` && desenharSegmentacao(linha)}
            <Line
                points={[
                    linha.pontos[0], linha.pontos[1] - 32,
                    linha.pontos[2], linha.pontos[3] - 32,
                    linha.pontos[2], linha.pontos[3] + 16,
                    linha.pontos[0], linha.pontos[1] + 16
                ]}
                onMouseEnter={e => {
                    if(modo === ''){
                        if(!movendo){
                            setHoveredLinha(`${linha.id_linha}`)
                            const container = e.target.getStage().container()
                            container.style.cursor = "pointer"
                        }
                    }
                }}
                onMouseLeave={e => {
                    setHoveredLinha(null)
                    const container = e.target.getStage().container()
                    container.style.cursor = movendo ? 'grabbing' : modo === '' ? 'default' : 'crosshair'
                }}
                onMouseDown={() => handleCLickLinha(linha, index, indexRegiao, hoveredLinha)}
                closed
                fill={`${linha.focado ? 'rgba(255, 255, 204, 0.5)' : 'transparent'}`}
            />
            <Text
                x={index + 1 > 9 ? linha.pontos[0] - 50 : linha.pontos[0] - 25}
                y={linha.pontos[1] - 20}
                text={`${linha.numero}`}
                fill={'black'}
                fontSize={40}
            />
            <Line
                points={linha.pontos}
                strokeWidth={hoveredLinha === `${linha.id_linha}` && !linha.focado ? 6 : 4}
                lineCap='round'
                stroke={hoveredLinha === `${linha.id_linha}` && !linha.focado ? theme.palette.custom.focada : linha.certificada ? theme.palette.custom.certificada : linha.focado ? theme.palette.custom.focada : theme.palette.custom.padrao}
                onMouseEnter={e => {
                    if(modo === ''){
                        if(!movendo){
                            setHoveredLinha(`${linha.id_linha}`)
                            const container = e.target.getStage().container()
                            container.style.cursor = "pointer"
                        }
                    }
                }}
                onMouseLeave={e => {
                    setHoveredLinha(null)
                    const container = e.target.getStage().container()
                    container.style.cursor = movendo ? 'grabbing' : modo === '' ? 'default' : 'crosshair'
                }}
                onMouseDown={() => handleFocarLinha(linha.id_linha)}
            />
            {(!linha.processando && !view ) &&
                <Group onMouseDown={() => handleFocarLinha(linha.id_linha)}>
                    <Circle
                        x={linha.pontos[0]}
                        y={linha.pontos[1]}
                        radius={hoveredPonto.id_regiao === linha.id_regiao && hoveredPonto.ponto === `${index}-start` ? 16 : 4}
                        fill={'white'}
                        stroke={'black'}
                        hitStrokeWidth={25}
                        onMouseEnter={() => setHoveredPonto({ id_regiao: linha.id_regiao, ponto: `${index}-start` })}
                        onMouseLeave={() => setHoveredPonto({ id_regiao: null, ponto: null })}
                    />
                    <Circle
                        x={linha.pontos[2]}
                        y={linha.pontos[3]}
                        radius={hoveredPonto.id_regiao === linha.id_regiao && hoveredPonto.ponto === `${index}-end` ? 16 : 4}
                        fill={'white'}
                        stroke={'black'}
                        hitStrokeWidth={25}
                        onMouseEnter={() => setHoveredPonto({ id_regiao: linha.id_regiao, ponto: `${index}-end` })}
                        onMouseLeave={() => setHoveredPonto({ id_regiao: null, ponto: null })}
                    />
                </Group>
            }
        </React.Fragment>
    )
    
    const RegiaoComponent = ({ regiao, handleFocarLinhaRegiao, hoveredPonto, setHoveredPonto, setRegiaoSelecionada, setCantoSelecionado, index, theme }) => (
        <React.Fragment>
            <Rect
                onMouseDown={() => handleFocarLinhaRegiao(regiao.id_regiao)}
                onMouseEnter={() => setHoveredPonto({ id_regiao: null, ponto: null })}
                x={regiao.superior.x}
                y={regiao.superior.y}
                width={regiao.inferior.x - regiao.superior.x}
                height={regiao.inferior.y - regiao.superior.y}
                stroke={regiao.focado ? theme.palette.custom.focada : theme.palette.custom.padrao}
                strokeWidth={4}
            />
            {(regiao.focado && !view) && (
                <>
                    <Circle
                        x={regiao.superior.x}
                        y={regiao.superior.y}
                        radius={hoveredPonto.id_regiao === regiao.id_regiao && hoveredPonto.ponto === `${index}-superior` ? 16 : 6}
                        fill={'white'}
                        stroke={'black'}
                        hitStrokeWidth={25}
                        onMouseEnter={() => setHoveredPonto({ id_regiao: regiao.id_regiao, ponto: `${index}-superior` })}
                        onMouseLeave={() => setHoveredPonto({ id_regiao: null, ponto: null })}
                        onMouseDown={() => {
                            setRegiaoSelecionada(index)
                            setCantoSelecionado('superior')
                        }}
                    />
                    <Circle
                        x={regiao.inferior.x}
                        y={regiao.inferior.y}
                        radius={hoveredPonto.id_regiao === regiao.id_regiao && hoveredPonto.ponto === `${index}-inferior` ? 16 : 6}
                        fill={'white'}
                        stroke={'black'}
                        hitStrokeWidth={25}
                        onMouseEnter={() => setHoveredPonto({ id_regiao: regiao.id_regiao, ponto: `${index}-inferior` })}
                        onMouseLeave={() => setHoveredPonto({ id_regiao: null, ponto: null })}
                        onMouseDown={() => {
                            setRegiaoSelecionada(index)
                            setCantoSelecionado('inferior')
                        }}
                    />
                    <Circle
                        x={regiao.superior.x}
                        y={regiao.inferior.y}
                        radius={hoveredPonto.id_regiao === regiao.id_regiao && hoveredPonto.ponto === `${index}-superiorXInferiorY` ? 16 : 6}
                        fill={'white'}
                        stroke={'black'}
                        hitStrokeWidth={25}
                        onMouseEnter={() => setHoveredPonto({ id_regiao: regiao.id_regiao, ponto: `${index}-superiorXInferiorY` })}
                        onMouseLeave={() => setHoveredPonto({ id_regiao: null, ponto: null })}
                        onMouseDown={() => {
                            setRegiaoSelecionada(index)
                            setCantoSelecionado('superiorXInferiorY')
                        }}
                    />
                    <Circle
                        x={regiao.inferior.x}
                        y={regiao.superior.y}
                        radius={hoveredPonto.id_regiao === regiao.id_regiao && hoveredPonto.ponto === `${index}-inferiorXSuperiorY` ? 16 : 6}
                        fill={'white'}
                        stroke={'black'}
                        hitStrokeWidth={25}
                        onMouseEnter={() => setHoveredPonto({ id_regiao: regiao.id_regiao, ponto: `${index}-inferiorXSuperiorY` })}
                        onMouseLeave={() => setHoveredPonto({ id_regiao: null, ponto: null })}
                        onMouseDown={() => {
                            setRegiaoSelecionada(index)
                            setCantoSelecionado('inferiorXSuperiorY')
                        }}
                    />
                </>
            )}
        </React.Fragment>
    )
    //

    const proxPagina = async () => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/imagens/`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });
    
            if (response.ok) {
                const images = await response.json();
                const currentImageIndex = images.findIndex(image => image.numero === parseInt(numeroImg));
                const nextImage = images[currentImageIndex + 1] || images[0]

                if(dono){
                    if(id_linha){
                        navigate(`/coleções/livro/${dono}/${id}/${nome}/imagem/${nextImage.numero}/${total}/${nextImage.id_imagem}?linha=${id_linha}`, {
                            state: { search: inputbusca }
                          })                      
                    } else {
                        navigate(`/coleções/livro/${dono}/${id}/${nome}/imagem/${nextImage.numero}/${total}/${nextImage.id_imagem}`)
                    }
                }else {
                    if(id_linha){
                        navigate(`/coleções/livro/${id}/${nome}/imagem/${nextImage.numero}/${total}/${nextImage.id_imagem}?linha=${id_linha}`, {
                            state: { search: inputbusca }
                          })                      
                    } else {
                        navigate(`/coleções/livro/${id}/${nome}/imagem/${nextImage.numero}/${total}/${nextImage.id_imagem}`)
                    }
                }

            } else {
                console.error('Erro ao obter as imagens:', await response.text());
            }
        } catch (error) {
            console.error('Erro ao buscar a próxima página:', error);
        }
    }
    
    const paginaAnterior = async () => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/imagens/`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });
    
            if (response.ok) {
                const images = await response.json();
                const currentImageIndex = images.findIndex(image => image.numero === parseInt(numeroImg));
                const prevImage = images[currentImageIndex - 1] || images[images.length - 1]

                if(dono){
                    if(id_linha){
                        navigate(`/coleções/livro/${dono}/${id}/${nome}/imagem/${prevImage.numero}/${total}/${prevImage.id_imagem}?linha=${id_linha}`, {
                            state: { search: inputbusca }
                          })
                    }else {
                        navigate(`/coleções/livro/${dono}/${id}/${nome}/imagem/${prevImage.numero}/${total}/${prevImage.id_imagem}`)
                    }
                }else{
                    if(id_linha){
                        navigate(`/coleções/livro/${id}/${nome}/imagem/${prevImage.numero}/${total}/${prevImage.id_imagem}?linha=${id_linha}`, {
                            state: { search: inputbusca }
                          })
                    }else {
                        navigate(`/coleções/livro/${id}/${nome}/imagem/${prevImage.numero}/${total}/${prevImage.id_imagem}`)
                    }
                }
    
                
            } else {
                console.error('Erro ao obter as imagens:', await response.text());
            }
        } catch (error) {
            console.error('Erro ao buscar a página anterior:', error);
        }
    }

    const handlePaginaInputChange = (e) => {
        setPaginaInput(e.target.value)
    }
    
    const handlePaginaInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            const novaPagina = parseInt(paginaInput)
            if (!isNaN(novaPagina)) {
                const path = window.location.pathname
                const barra = path.lastIndexOf('/')
                const barra2 = path.lastIndexOf('/', barra - 1)
                const novaPaginaPath = `${path.substring(0, barra2 + 1)}${novaPagina}${path.substring(barra)}`
                navigate(novaPaginaPath)
            }
        }
    }

    const deletePagina = async () => {
        try{
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/imagens/${id_imagem}/`, {
                method: 'DELETE',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
    
            if (!response.ok) {
                console.error(`Erro ao deletar a imagem ${id_imagem}`)
            } else{
                voltar()
            }
        }catch(error){
            handleCrash()
        }
    }

    useEffect(() => {
        if (linkImg) {
            renderizarImagem()
        }
    }, [linkImg, renderizarImagem])

    useEffect(() => {
        if (linkImg && imagem && stageRef.current) {
            const stage = stageRef.current
            const stageWidth = stage.width()
            const stageHeight = stage.height()
            const imgWidth = imagem.width
            const imgHeight = imagem.height
    
            if (imgWidth && imgHeight) {
                const scaleWidth = stageWidth / imgWidth
                const scaleHeight = stageHeight / imgHeight
                const initialScale = Math.min(scaleWidth, scaleHeight)
    
                const x = (stageWidth - imgWidth * initialScale) / 2
                const y = (stageHeight - imgHeight * initialScale) / 2
    
                setStage({
                    scale: initialScale,
                    x: x,
                    y: y
                })
            }
        }
    }, [linkImg, imagem])

    useEffect(() => {
        if (id_linha !== null) {
            handleFocarLinha(id_linha, true)
        }
    }, [id_linha, imagem])

    const voltar = () => {
        if(id_linha){
            navigate(`/busca?search=${inputbusca}`)
        }else if(dono){
            navigate(`/coleções/livro/${dono}/${id}/${nome}`)
        }else{
            navigate(`/coleções/livro/${id}/${nome}`)
        }
    }

    useAtalho('f', () => {
        if (!view) setModo('desenho');
    })
    useAtalho('Escape', () => {
        if (!view) reset();
    })
    useAtalho('a', () => {
        if (!view) setModo('area');
    })
    useAtalho('Delete', () => {
        if (!view) deletarSelecionados();
    })
    useAtalhoCombo('z', () => {
        if (!view) undo();
    })
    useAtalhoCombo('y', () => {
        if (!view) redo();
    })
    
    return (
        <Box className={`flex flex-col h-[100vh] w-[100vw] ${isBrowser ? '' : 'bg-black'}`}>
            <Box className={`flex justify-between w-[100vw] ${isBrowser ? '' : 'h-[100px]'} items-center h-16`} sx={{bgcolor: theme.palette.custom.cor_menu_t}}>
                <div className='ml-5'>
                    <Botao variant="default" onClick={() => voltar()}>
                        <IoIosArrowBack size={isBrowser ? 20 : 40}/> Voltar
                    </Botao>
                </div>
                <Box className='flex justify-center items-center h-[100%] -mr-[10vw] gap-3 '>
                    <Box className='flex justify-center gap-3 '>    
                        <Typography variant={isBrowser ? 'h6' : 'h4' } sx={{ color: theme.palette.custom.cor_1 }}>Livro {nome}</Typography>
                    </Box>
                    <Botao variant="default" onClick={() => paginaAnterior()}>
                        <IoIosArrowBack size={isBrowser ? 20 : 40}/>
                    </Botao>
                    <TextField
                        variant="outlined"
                        value={paginaInput}
                        onChange={handlePaginaInputChange}
                        onKeyPress={handlePaginaInputKeyPress}
                        size="small"
                        sx={{
                            bgcolor: theme.palette.custom.cor_1,
                            borderRadius: '5px',
                            border: `1px solid ${theme.palette.custom.cor_3}`,
                            textAlign: 'center'
                        }}
                        style={{
                            width: isBrowser ? `${(paginaInput.length * 10) + (total.length * 140) / total.length}px` : `${(paginaInput.length * 10) + (total.length * 140) / total.length}px`,
                            fontSize: '1.7rem'
                        }}
                        autoComplete="off"
                        autoCorrect="off"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                style: {
                                    textAlign: 'center',
                                    color: theme.palette.custom.cor_2
                                }
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    Página
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment  position="end">
                                    de {total}
                                </InputAdornment>
                            )
                        }}
                    />
                    <Botao variant="default" onClick={() => proxPagina()}>
                        <IoIosArrowForward size={isBrowser ? 20 : 40}/>
                    </Botao>
                </Box>
                <Box className='flex w-[30%] items-center justify-end'>
                    <Box className='w-[90%] mr-5'>
                        <BarraProgresso 
                            dados={infoTreino}
                            status={treinando}
                        />
                    </Box>
                    { !view && 
                        <Box className={`flex justify-center gap-3 ${isBrowser ? 'w-10' : 'w-20'}`}>
                            <button onClick={() => deletePagina()} title='Deletar arquivos selecionados' className='botao_3 flex items-center justify-center -ml-7'>
                                <PiTrash color={theme.palette.custom.cor_texto} size={isBrowser ? 20 : 40} />
                            </button>
                        </Box>
                    }
                </Box>
            </Box>
            {(modalValidar && !view) && (
                <Validacao
                    view={view}
                    setModalValidar={setModalValidar}
                    setLinhaSelecionada={setLinhaSelecionada}
                    linhaSelecionada={linhaSelecionada}
                    dadosValidar={dadosValidar}
                    getDados={getDados}
                    setProblemModal={setProblemModal}
                />
            )}
            <Modal
                open={showConfirmModal}
                onClose={cancelEditLinha}
                aria-labelledby="confirm-edit-title"
                aria-describedby="confirm-edit-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Typography id="confirm-edit-title" variant="h6" component="h2">
                        Confirmar Edição
                    </Typography>
                    <Typography id="confirm-edit-description" sx={{ mt: 2 }}>
                        Esta edição irá retranscrever a linha.<br></br>Deseja realmente realizar a alteração?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Botao variant="default" onClick={confirmEditLinha}>
                            Sim
                        </Botao>
                        <Botao variant="default_negativo" onClick={cancelEditLinha}>
                            Não
                        </Botao>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={transcrevendoModal}
                aria-labelledby="confirm-edit-title"
                aria-describedby="confirm-edit-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 550, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Typography id="confirm-edit-title" variant="h6" component="h2">
                        Transcricao em Progresso
                    </Typography>
                    <Typography id="confirm-edit-description" sx={{ mt: 2 }}>
                        Uma operação de transcrição de imagem está em progresso.<br></br>Algumas ações estão bloqueadas até a conclusão.
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Botao variant="default" onClick={() => setTranscrevendoModal(false)}>
                            Ok
                        </Botao>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={problemModal}
                aria-labelledby="confirm-edit-title"
                aria-describedby="confirm-edit-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Typography id="confirm-edit-title" variant="h6" component="h2">
                        Falha ao realizar a operação
                    </Typography>
                    <Typography id="confirm-edit-description" sx={{ mt: 2 }}>
                        Estamos com problemas técnicos.<br></br>Aguarde um pouco ou repita a ação mais tarde.
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Botao variant="default" onClick={() => setProblemModal(false)}>
                            Ok
                        </Botao>
                    </Box>
                </Box>
            </Modal>
            { ( isBrowser && !view ) && 
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={menuAberto}
                    sx={{
                        '& .MuiDrawer-paper': {
                            ml: '15px',
                            height: '200px',
                            backgroundColor: '#808080',
                            padding: '2px',
                            mt: '20%',
                            transition: 'transform 0.3s ease',
                            transform: menuAberto ? 'translateX(0)' : 'translateX(-100%)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '20px',
                        },
                    }}
                >
                    <Tooltip
                        title={
                            <Box sx={{
                                textAlign: 'start',
                                '& img': {
                                    width: '100%',
                                    height: 'auto',
                                },
                                backgroundColor: theme.palette.custom.cor_tolltip,
                            }}>
                                <img 
                                    className='rounded-t-sm'
                                    src="/imagens/tolltip_1.png" 
                                    alt="Seleção e Arrasto" 
                                />
                                <Typography variant="h6" sx={{ paddingLeft: 1, color: theme.palette.custom.cor_1 }} fontWeight="bold">
                                    Seleção e Arrasto (S)
                                </Typography>
                                <Typography variant="body2" sx={{paddingBottom: 1, paddingLeft: 1, color: theme.palette.custom.cor_1}}>
                                    Movimente a imagem ou selecione linhas/regiões.
                                </Typography>
                            </Box>
                        }
                        placement="bottom-end"
                        slotProps={{
                            tooltip: {
                                sx: {
                                    backgroundColor: 'black',
                                    padding: 0,
                                    paddingLeft: 0.2,
                                    paddingBottom: 0.2,
                                    boxShadow: '-8px 8px 10px rgba(0, 0, 0, 0.7)'
                                },
                            },
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [60, -120],
                                  },
                                },
                              ],
                            },
                          }}
                    >
                        <IconButton
                            onClick={() => reset()}
                            sx={{
                                margin: '10px',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                backgroundColor: isModoAtivo('') ? '#ffffff' : '#000000',
                                color: 'white',
                                '&:hover': { backgroundColor: '#757575' },
                            }}
                        >
                            <HiCursorClick color={isModoAtivo('') ? '#000000' : '#ffffff'} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={
                            <Box sx={{ 
                                textAlign: 'start',
                                '& img': {
                                    width: '100%',
                                    height: 'auto',
                                },
                                backgroundColor: theme.palette.custom.cor_tolltip,
                            }}>
                                <img 
                                    className='rounded-t-sm'
                                    src="/imagens/tolltip_2.png" 
                                    alt="Desenho " 
                                />
                                <Typography variant="h6" sx={{ paddingLeft: 1, color: theme.palette.custom.cor_1 }} fontWeight="bold">
                                    Desenhar Linhas (F)
                                </Typography>
                                <Typography variant="body2" sx={{paddingBottom: 1, paddingLeft: 1, color: theme.palette.custom.cor_1}}>
                                    Demarque novas linhas dentro de uma região.
                                </Typography>
                            </Box>
                        }
                        placement="bottom-end"
                        slotProps={{
                            tooltip: {
                                sx: {
                                    backgroundColor: 'black',
                                    padding: 0,
                                    paddingLeft: 0.2,
                                    paddingBottom: 0.2,
                                    boxShadow: '-8px 8px 10px rgba(0, 0, 0, 0.7)'
                                },
                            },
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [60, -120],
                                  },
                                },
                              ],
                            },
                          }}
                    >
                        <IconButton
                            onClick={() => setModo('desenho')}
                            sx={{
                                margin: '10px',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                backgroundColor: isModoAtivo('desenho') ? '#ffffff' : '#000000',
                                color: 'white',
                                '&:hover': { backgroundColor: '#757575' },
                            }}
                        >
                            <FaPenNib color={isModoAtivo('desenho') ? '#000000' : '#ffffff'} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={
                            <Box sx={{ 
                                textAlign: 'start',
                                '& img': {
                                    width: '100%',
                                    height: 'auto',
                                },
                                backgroundColor: theme.palette.custom.cor_tolltip,
                            }}>
                                <img 
                                    className='rounded-t-sm'
                                    src="/imagens/tolltip_3.png" 
                                    alt="Seleção e Arrasto" 
                                />
                                <Typography variant="h6" sx={{ paddingLeft: 1, color: theme.palette.custom.cor_1 }} fontWeight="bold">
                                    Desenhar Regiões (A)
                                </Typography>
                                <Typography variant="body2" sx={{paddingBottom: 1, paddingLeft: 1, color: theme.palette.custom.cor_1}}>
                                    Demarque novas regiões dentro de uma imagem.
                                </Typography>
                            </Box>
                        }
                        placement="bottom-end"
                        slotProps={{
                            tooltip: {
                                sx: {
                                    backgroundColor: 'black',
                                    padding: 0,
                                    paddingLeft: 0.2,
                                    paddingBottom: 0.2,
                                    boxShadow: '-8px 8px 10px rgba(0, 0, 0, 0.7)'
                                },
                            },
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [60, -120],
                                  },
                                },
                              ],
                            },
                          }}
                    >
                        <IconButton
                            onClick={() => setModo('area')}
                            sx={{
                                margin: '10px',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                backgroundColor: isModoAtivo('area') ? '#ffffff' : '#000000',
                                color: 'white',
                                '&:hover': { backgroundColor: '#757575' },
                            }}
                        >
                            <PiSelectionPlusBold color={isModoAtivo('area') ? '#000000' : '#ffffff'} />
                        </IconButton>
                    </Tooltip>
                </Drawer>
            }
            <Box className='flex h-[94vh] overflow-hidden w-[100vw]'>
                    <Box className='h-full w-[70%] bg-black z-0'>
                    {loading ? (
                        <div className={`flex justify-center items-center h-[90vh] ${isBrowser ? '' : 'w-[100vw]'}`}>
                            <div><CircularProgress size={isBrowser ? 30 : 80} style={{ color: "white" }}/></div>
                        </div>
                    ) : (
                        <Stage
                            ref={stageRef}
                            width={isBrowser ? window.innerWidth * 0.7 : window.innerWidth}
                            height={window.innerHeight * 0.94}
                            onWheel={handleZoom}
                            onMouseDown={(e) => handleMouseDown(e, stageRef, setMovendo, setLastMousePosition, modo, handleCriacaoAreaStart, handleDesenhoStart, handleSelecionarLinha, handleSelecionarRegiao, setMenuAberto(false))}
                            onMouseMove={() => handleMouseMove(stageRef, movendo, lastMousePosition, setStage, setLastMousePosition, modo, handleCriacaoAreaMove, handleDesenho, linhaSelecionada, regiaoSelecionada, handleModificacaoLinha, handleModificacaoRegiao, view)}
                            onMouseUp={(e) => handleMouseUp(e, setMovendo, setLastMousePosition, modo, handleCriacaoAreaFim, handleDesenhoFim, linhaSelecionada, editarLinha, setLinhaSelecionada, setPontoSelecionado, regiaoSelecionada, setRegiaoSelecionada, setCantoSelecionado, editarRegiao, setMenuAberto(true))}
                            scaleX={stage.scale}
                            scaleY={stage.scale}
                            x={stage.x}
                            y={stage.y}
                            style={{
                                cursor: movendo ? 'grabbing' : modo === '' ? 'default' : 'crosshair',
                                backgroundColor: 'black',
                            }}
                        >
                            <Layer>
                                <Image
                                    image={imagem}
                                    ref={imagemRef}
                                    width={imagem.width * nivelZoom}
                                    height={imagem.height * nivelZoom}
                                />
                                {/* Usando os componentes criados lá em cima como função de memo */}
                                {regioes.map((regiao, index) => (
                                    <RegiaoComponent
                                        key={index}
                                        regiao={regiao}
                                        handleFocarLinhaRegiao={handleFocarLinhaRegiao}
                                        hoveredPonto={hoveredPonto}
                                        setHoveredPonto={setHoveredPonto}
                                        setRegiaoSelecionada={setRegiaoSelecionada}
                                        setCantoSelecionado={setCantoSelecionado}
                                        theme={theme}
                                        index={index}
                                    />
                                ))}
                                {regioes.map((regiao) => (
                                    linhas.filter((linha) => regiao.contemLinha(linha.pontos)).map((linha, index) => (
                                        <LinhaComponent
                                            key={`${regiao.id}-${index}`}
                                            linha={linha}
                                            handleFocarLinha={handleFocarLinha}
                                            hoveredLinha={hoveredLinha}
                                            setHoveredLinha={setHoveredLinha}
                                            modo={modo}
                                            movendo={movendo}
                                            theme={theme}
                                            index={index}
                                            indexRegiao={regioesOrdenadas.indexOf(regiao)}
                                        />
                                    ))
                                ))}
                                {linhaAtual.length > 0 && (
                                    <Line 
                                        points={linhaAtual}
                                        stroke={theme.palette.custom.padrao}
                                    />
                                )}
                                {areaAtual.length === 2 && (
                                    <Rect
                                        x={Math.min(areaAtual[0].x, areaAtual[1].x)}
                                        y={Math.min(areaAtual[0].y, areaAtual[1].y)}
                                        width={Math.abs(areaAtual[1].x - areaAtual[0].x)}
                                        height={Math.abs(areaAtual[1].y - areaAtual[0].y)}
                                        stroke="blue"
                                        strokeWidth={2}
                                        dash={[4, 4]}
                                    />
                                )}
                            </Layer>
                        </Stage>
                    )}
                </Box>
                {(loading && isBrowser) ? (
                    <Box display="flex" flexDirection="column" width={'30%'} mx={2} mr={-5} gap={2} alignItems="center" overflow="auto">
                        {[...Array(20)].map((_, index) => (
                            <Box key={index} display="flex" width={'92%'} mx={2} alignItems="center">
                                <Skeleton variant="text" width={40} height={40} />
                                <Box display="flex" ml={-2} px={2} width={'100%'} alignItems="center" justifyContent="center" mt={3} className="cursor-pointer rounded-lg">
                                    <Skeleton variant="rectangular" width={'90%'} height={40} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    isBrowser && (
                        <ListRegiao
                            view={view}
                            regioes={regioes}
                            linhas={linhas}
                            validarLinha={validarLinha}
                            linhaFocadaId={linhaFocadaId}
                            linhaFocadaRef={linhaFocadaRef}
                            handleFocarLinha={handleFocarLinha}
                            getDados={getDados}
                            loading={loading}
                            setProblemModal={setProblemModal}
                        />
                    )
                )}
            </Box>
        </Box>
    )
}

export default Transcricao