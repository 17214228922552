const Atualizar = ({ setModalUpdate, contaSelecionada, receberDados }) => {
    const token = localStorage.getItem('key')

    const atualizar = async (id) => {
        try {

            const response = await fetch(`https://transcritor-ia.com:8086/api/administracao/usuario/${contaSelecionada.id}/atualizar-plano/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id_plano: id
                }),
            })

            if (response.ok) {
                receberDados()
            }

            setModalUpdate(false)
        } catch (error) {
            console.error('Erro ao tentar atualizar as contas:', error)
        }
    }

    return (
        <main className='absolute end-36'>
            <div className='flex flex-col items-center rounded-lg sombra bg-white px-2 py-2 '>
                <div className="h-full ">
                    <div className="flex flex-col items-start gap-1">
                        <div className="flex gap-2">
                            <button onClick={() => atualizar(4)} >
                                Colaborador
                            </button>
                        </div>
                        <div className="flex gap-2">
                            <button onClick={() => atualizar(3)} >
                                Associado
                            </button>
                        </div>
                        <div className="flex gap-2">
                            <button onClick={() => atualizar(2)} >
                                Curador
                            </button>
                        </div>
                        <div className="flex gap-2">
                            <button onClick={() => atualizar(1)} >
                                Administrador
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Atualizar