import { LinearProgress, Box, Typography, useTheme, Tooltip } from "@mui/material"

const BarraProgresso = ({ dados, status }) => {
    const alvo = dados.linhas_certificadas + dados.faltam_para_treinamento
    const theme = useTheme()
    const progresso = (dados.linhas_certificadas / dados.total_linhas) * 100
    const target = (alvo / dados.total_linhas) * 100

    let historicoRecente;
    try {
        historicoRecente = dados.modelos[0].historico[dados.modelos[0].historico.length - 1];
    } catch {
        historicoRecente = null;
    }

    // oculta o texto de progresso quando está nas pontas para não ficar acontecendo overflow
    const esconderProgresso = progresso < 5 || progresso > 95

    return (
        <Box sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
            <Tooltip
                title={
                    <Box sx={{
                        textAlign: 'start',
                        borderRadius: '5px 5px 5px 5px',
                        paddingRight: 1,
                        backgroundColor: theme.palette.custom.cor_menu_t,
                    }}>
                        {status === false ? (
                            <>
                                { historicoRecente &&
                                <>
                                    <Typography variant="body2" sx={{ paddingBottom: 1, paddingLeft: 1, color: theme.palette.custom.cor_1 }}>
                                        CER: {historicoRecente.va_cer.toFixed(2)}%
                                    </Typography>
                                    { historicoRecente.data && historicoRecente.horario &&
                                        <Typography variant="body2" sx={{ paddingBottom: 1, paddingLeft: 1, color: theme.palette.custom.cor_1 }}>
                                            Treinado em {historicoRecente.data} às {historicoRecente.horario}
                                        </Typography>
                                    }
                                </>
                                }
                                <Typography variant="body2" sx={{ paddingBottom: 1, paddingLeft: 1, color: theme.palette.custom.cor_1 }}>
                                    Faltam {alvo - dados.linhas_certificadas} linhas para o novo treinamento.
                                </Typography>
                            </>
                        ):(
                            <>
                                <Typography variant="body2" sx={{ paddingBottom: 1, paddingLeft: 1, color: theme.palette.custom.cor_1 }}>
                                    O modelo está treinando!
                                </Typography>
                                { historicoRecente &&
                                <>
                                    <Typography variant="body2" sx={{ paddingBottom: 1, paddingLeft: 1, color: theme.palette.custom.cor_1 }}>
                                        Ùltimo CER: {historicoRecente.va_cer.toFixed(2)}%
                                    </Typography>
                                    { historicoRecente.data && historicoRecente.horario &&
                                        <Typography variant="body2" sx={{ paddingBottom: 1, paddingLeft: 1, color: theme.palette.custom.cor_1 }}>
                                            Treinado em {historicoRecente.data} às {historicoRecente.horario}
                                        </Typography>
                                    }
                                </>
                                }
                            </>                            
                        )}
                    </Box>
                }
                slotProps={{
                    tooltip: {
                        sx: {
                            padding: 0,
                            paddingLeft: 0,
                            paddingBottom: 0,
                            borderRadius: 2,
                            boxShadow: '-8px 8px 10px rgba(0, 0, 0, 0.7)'
                        },
                    },
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                        },
                      ],
                    },
                }}
            >
                <LinearProgress
                    variant="determinate"
                    value={progresso}
                    sx={{
                        height: 30,
                        backgroundColor: theme.palette.custom.cor_fundo,
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: theme.palette.custom.certificada,
                        },
                    }}
                />
            </Tooltip>

            <Box
                sx={{
                    position: "absolute",
                    top: "0px",
                    left: `${target}%`,
                    transform: "translateX(-50%)",
                    width: "1px",
                    height: "30px",
                    backgroundColor: "black",
                }}
            />

            <Box
                sx={{
                    position: "absolute",
                    top: "2px",
                    left: `${progresso}%`,
                    transform: "translateX(-50%)",
                    whiteSpace: "nowrap",
                    display: esconderProgresso ? "none" : "block",
                }}
            >
                <Typography sx={{ marginRight: "25px", color: theme.palette.custom.cor_3 }}>{dados.linhas_certificadas}</Typography>
            </Box>

            <Box sx={{ position: "absolute", top: "2px", right: 0 }}>
                <Typography sx={{ marginRight: "10px", color: theme.palette.custom.cor_3 }}>{dados.total_linhas} Linhas</Typography>
            </Box>
        </Box>
    )
}

export default BarraProgresso