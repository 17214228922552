

const NotFound = () => {


    return (
        <div className="flex items-center justify-center w-[100vw] h-[100vh]">
            404
        </div>
    );
};

export default NotFound;