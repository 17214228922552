import { Route, Routes } from "react-router-dom";
import Homepage from "./root/paginas/homepage";
import Colecoes from "./root/paginas/colecoes";
import Imagens from "./root/paginas/imagens";
import Contas from "./root/paginas/admin/contas";
import Transcricao from "./root/paginas/transcricao";
import NotFound from "./root/paginas/404";
import BuscaPage from "./root/paginas/buscar";
import Equipe from "./root/paginas/equipe";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import ProtectedRoute from "./root/util/protected_route";
//import "@fontsource/pinyon-script";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/equipe' element={<Equipe />} />
        <Route path='/coleções' element={<ProtectedRoute element={Colecoes} />} exact />
        <Route path='/coleções/livro/:dono/:id/:nome' element={<ProtectedRoute element={Imagens} />} />
        <Route path='/coleções/livro/:id/:nome' element={<ProtectedRoute element={Imagens} />} />
        <Route path='/coleções/livro/:dono/:id/:nome/imagem/:numeroImg/:total/:id_imagem' element={<ProtectedRoute element={Transcricao} />} />
        <Route path='/coleções/livro/:id/:nome/imagem/:numeroImg/:total/:id_imagem' element={<ProtectedRoute element={Transcricao} />} />
        <Route path='/admin/contas' element={<ProtectedRoute element={Contas} />} />
        <Route path='/busca' element={<BuscaPage />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;