import { useEffect } from "react"

export const useAtalhoCombo = (atalho, callback) => {
  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === atalho) {
      return callback(event)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  })
}
