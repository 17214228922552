import React, { useCallback, useEffect, useRef, useState } from 'react'
//import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { PiFile, PiFilePdf, PiImage } from 'react-icons/pi'
import DropDown from '../drop_down'
import NovoEscrevente from '../modais/modal_novo_escrevente'
import NovoTipoLivro from '../modais/modal_novo_tipo_livro'
import { Box, IconButton, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Delete, RotateLeft, RotateRight } from '@mui/icons-material'

const WizzardLivro = ({ setModalWizzard, getLivros }) => {
    const token = localStorage.getItem('key')
    const [escreventes, setEscreventes] = useState([])
    const [tipoDocumento, setTipoDocumento] = useState([])
    const [modalUpload, setModalUpload] = useState(false)
    const [files, setFiles] = useState([])
    const [enviando, setEnviando] = useState(false)
    const [idLivro, setIdLivro] = useState()
    const [dadosLivro, setDadosLivro] = useState({
        nome: "",
        local: "",
        periodo_inicial: "",
        periodo_final: "",
        id_tipo_documento: 0,
        id_escrevente: 0
    })
    const [arquivoSelecionado, setArquivoSelecionado] = useState(null)
    const [indexSelecionado, setIndexSelecionado] = useState(null)
    const [modalEscrevente, setModalEscrevente] = useState(false)
    const [modalTipoLivro, setModalTipoLivro] = useState(false)
    const [graus, setGraus] = useState({})
    const [rotacao, setRotacao] = useState(90)
    const canvasRef = useRef(null)

    const getNovoID = async () => {
        try{
            const response = await fetch('https://transcritor-ia.com:8086/api/livro/',{
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })

            if (response.ok){
                const resposta = await response.json()
                setIdLivro(resposta[resposta.length - 1].id_livro)
            }
        }catch(error){
            console.log('erro ao tentar obter livros',error)
        }
    }

    const criarLivro = async () => {
        let livroForm = { ...dadosLivro };
        
        if (!livroForm.periodo_inicial) {
            delete livroForm.periodo_inicial;
        }

        if (!livroForm.periodo_final) {
            delete livroForm.periodo_final;
        }
        
        const response = await fetch('https://transcritor-ia.com:8086/api/livro/', {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(livroForm)
        })
        
        if (response.ok) {
            getLivros()
            getNovoID()
            setModalUpload(true)
        } else {
            console.error('Falha ao criar livro:', response.status, await response.text());
        }
    }   
    
    const getEscrivaos = async () => {
        const response = await fetch('https://transcritor-ia.com:8086/api/escrevente/', {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })

        if (response.ok) {
            const resposta = await response.json()
            setEscreventes(resposta)
        }
    }

    const getTipoDoc = async () => {
        const response = await fetch('https://transcritor-ia.com:8086/api/tipo-documento/', {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })

        if (response.ok) {
            const resposta = await response.json()
            setTipoDocumento(resposta)
        }
    }

    ///
    const handleFileChange = (e) => {
        const arquivosSelecionados = Array.from(e.target.files)
        setFiles(arquivosSelecionados)
        const firstImageIndex = arquivosSelecionados.findIndex(file => file.type.startsWith('image/'))
        if (firstImageIndex >= 0) {
          const firstImage = arquivosSelecionados[firstImageIndex]
          const imageUrl = URL.createObjectURL(firstImage)
          setArquivoSelecionado(imageUrl)
          setIndexSelecionado(firstImageIndex)
          setGraus((prev) => ({ ...prev, [firstImageIndex]: 0 }))
        }
      }
    
    const removerArquivo = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)

    const newRotations = { ...graus }
    delete newRotations[index]
    setGraus(newRotations)

    if (indexSelecionado === index) {
        const remainingImageIndex = newFiles.findIndex(file => file.type.startsWith('image/'))
        setIndexSelecionado(remainingImageIndex)
        setArquivoSelecionado(
        remainingImageIndex >= 0 ? URL.createObjectURL(newFiles[remainingImageIndex]) : null
        )
    }
    }
    
    const rotacionar = (graus) => {
    if (indexSelecionado !== null) {
        setGraus((prev) => ({
        ...prev,
        [indexSelecionado]: ((prev[indexSelecionado] || 0) + graus + 360) % 360,
        }))
    }
    }

    // const renderRotacionado = () => {
    // const canvas = canvasRef.current
    // if (!canvas || !arquivoSelecionado) return

    // const ctx = canvas.getContext('2d')
    // const img = new Image()
    // img.src = arquivoSelecionado

    // img.onload = () => {
    //     canvas.width = img.width
    //     canvas.height = img.height

    //     const centerX = canvas.width / 2
    //     const centerY = canvas.height / 2

    //     ctx.clearRect(0, 0, canvas.width, canvas.height)
    //     ctx.save()
    //     ctx.translate(centerX, centerY)
    //     ctx.rotate(((graus[indexSelecionado] || 0) * Math.PI) / 180)
    //     ctx.drawImage(img, -img.width / 2, -img.height / 2)
    //     ctx.restore()
    // }
    // }

    // Sugestão do chatGPT para correção do código acima.
    const renderRotacionado = useCallback(() => {
        const canvas = canvasRef.current;
        if (!canvas || !arquivoSelecionado) return;
    
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = arquivoSelecionado;
    
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
    
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
    
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.save();
            ctx.translate(centerX, centerY);
            ctx.rotate(((graus[indexSelecionado] || 0) * Math.PI) / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);
            ctx.restore();
        };
    }, [arquivoSelecionado, graus, indexSelecionado]);

    const getIcones = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        return <PiImage className="text-blue-500 text-xl" />
        case 'pdf':
        return <PiFilePdf className="text-red-500 text-xl" />
        default:
        return <PiFile className="text-gray-500 text-xl" />
    }
    }

    React.useEffect(() => {
        renderRotacionado()
        // eslint-disable-next-line
    }, [graus, arquivoSelecionado, renderRotacionado])

    const enviarArquivos = async () => {
    if (!files.length) {
        alert('Selecione pelo menos uma imagem para enviar.')
        return
    }

    setEnviando(true)

    for (let i = 0; i < files.length; i++) {
        if (files[i].type.startsWith('image/')) {
        const imageUrl = URL.createObjectURL(files[i])
        const canvas = canvasRef.current

        setArquivoSelecionado(imageUrl) 
        setIndexSelecionado(i)

        await new Promise((resolve) => {
            renderRotacionado()
            setTimeout(resolve, 200)
        })

        if (canvas) {
            await new Promise((resolve, reject) => {
            canvas.toBlob(async (blob) => {
                const formData = new FormData()
                formData.append('arquivos', blob, `rotated-image-${i}.png`)

                try {
                const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${idLivro}/transcrever/`, {
                    method: 'POST',
                    headers: {
                    accept: 'application/json',
                    Authorization: `Token ${token}`,
                    },
                    body: formData,
                })

                if (!response.ok) {
                    console.error(`Erro ao enviar a imagem rotacionada para o arquivo ${i}.`)
                }
                resolve()
                } catch (error) {
                console.error(`Erro ao enviar a imagem ${i}:`, error)
                reject(error)
                }
            })
            })
        }
        }
    }

    setEnviando(false)
    setModalWizzard(false)
    }
    ///

    useEffect(() => {
        getEscrivaos()
        getTipoDoc()
        // eslint-disable-next-line
    }, [])

    return (
        <div className='absolute h-full w-full flex items-center justify-center'>
            <div onClick={() => { setModalWizzard(false) }} className='fundo-desfocado z-10'></div>
            { modalEscrevente &&
                <NovoEscrevente
                    setModal={setModalEscrevente}
                    receberEscreventes={getEscrivaos}
                />
            }
            { modalTipoLivro &&
                <NovoTipoLivro
                    setModal={setModalTipoLivro}
                    receberEscreventes={getTipoDoc}
                />
            }
            { !modalUpload ? (
                <div className='flex flex-col items-center rounded-lg sombra bg-white min-w-[400px] w-[30%] max-h-[95%] z-20 overflow-auto'>
                    <div className='flex flex-col items-center justify-center bg-white w-full py-5 mb-2'>
                        <h1 className='font-bold text-3xl'>NOVO LIVRO</h1>
                        <div className='flex flex-col gap-2 w-[90%]'>
                            <h1>Nome</h1>
                            <input
                                value={dadosLivro.nome}
                                onChange={(e) => setDadosLivro({ ...dadosLivro, nome: e.target.value })}
                                type='text'
                                className='input_2'
                            />

                            <h1>Local</h1>
                            <input
                                value={dadosLivro.local}
                                onChange={(e) => setDadosLivro({ ...dadosLivro, local: e.target.value })}
                                type='text'
                                className='input_2'
                            />
                            <div className='flex justify-between gap-5'>
                                <div className='flex flex-col w-full'>
                                    <h1>Periodo Inicial</h1>
                                    <input
                                        value={dadosLivro.periodo_inicial}
                                        onChange={(e) => setDadosLivro({ ...dadosLivro, periodo_inicial: e.target.value })}
                                        type='date'
                                        className='input_2'
                                    />
                                </div>
                                <div className='flex flex-col w-full'>
                                    <h1>Periodo Final</h1>
                                    <input
                                        value={dadosLivro.periodo_final}
                                        onChange={(e) => setDadosLivro({ ...dadosLivro, periodo_final: e.target.value })}
                                        type='date'
                                        className='input_2'
                                    />
                                </div>
                            </div>
                            <h1>Tipo do Documento</h1>
                            <DropDown
                                opcoes={tipoDocumento}
                                chaveId="id_tipo_documento"
                                value={dadosLivro.id_tipo_documento}
                                onChange={(id) => setDadosLivro({ ...dadosLivro, id_tipo_documento: id })}
                                placeholder="Selecione ou busque um tipo de documento"
                                onAdd={() => setModalTipoLivro(true)}
                            />
                            <h1>Escrevente</h1>
                            <DropDown
                                opcoes={escreventes}
                                chaveId="id_escrevente"
                                value={dadosLivro.id_escrevente}
                                onChange={(id) => setDadosLivro({ ...dadosLivro, id_escrevente: id })}
                                placeholder="Selecione ou busque um escrevente"
                                onAdd={() => setModalEscrevente(true)}
                            />
                            {dadosLivro.nome && dadosLivro.local  && dadosLivro.id_escrevente ? (
                                <div className={`relative flex items-center justify-center w-[95%] mt-3`}>
                                    <button onClick={() => (criarLivro())} className='botao_1 mb-2'>
                                        <h1 className='text-2xl p-2'>Criar livro</h1>
                                    </button>
                                </div>
                            ) : (
                                <div className={`relative flex items-center justify-center w-[95%] mt-3`}>
                                    <button disabled className='botao_1 mb-2'>
                                        <h1 className='text-2xl text-gray-500 p-2'>Criar livro</h1>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ):(
                <div className="flex flex-col items-center rounded-lg sombra bg-white min-w-[400px] px-10 max-w-[90%] max-h-[90%] z-20">
                    <div className="flex gap-4 items-center justify-center bg-white w-full py-5">
                        {files.length > 0 && (
                            <div className="flex-grow h-[70vh] overflow-auto">
                                <ul className="flex flex-col gap-2">
                                    {files.map((file, index) => (
                                        <ListItem
                                            key={index}
                                            sx={{ mb: 1, cursor: 'pointer', bgcolor: index === indexSelecionado ? '#f5f5f5' : 'inherit' }}
                                            onClick={() => {
                                            setArquivoSelecionado(URL.createObjectURL(file));
                                            setIndexSelecionado(index);
                                            }}
                                        >
                                            <ListItemIcon>{getIcones(file.name)}</ListItemIcon>
                                            <ListItemText primary={file.name} />
                                            <IconButton onClick={() => removerArquivo(index)} color="error">
                                            <Delete />
                                            </IconButton>
                                        </ListItem>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className="flex flex-col gap-2">
                            {arquivoSelecionado && (
                                <>
                                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                        <IconButton onClick={() => rotacionar(-rotacao)}>
                                            <RotateLeft />
                                        </IconButton>

                                        <input
                                            value={rotacao}
                                            onChange={(e) => setRotacao(parseInt(e.target.value, 10) || 0)}
                                            style={{
                                            width: 50,
                                            textAlign: 'center',
                                            border: '1px solid #ccc',
                                            borderRadius: 4,
                                            padding: '4px 8px',
                                            }}
                                        />

                                        <IconButton onClick={() => rotacionar(rotacao)}>
                                            <RotateRight />
                                        </IconButton>
                                    </Box>

                                    <canvas ref={canvasRef} style={{ width: 600, height: 400, border: '1px solid #ccc' }} />
                                </>
                            )}
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                className="border border-gray-300 rounded p-2"
                            />
                            {enviando ? (
                                <button disabled className="botao_1 flex justify-center">
                                    <FaSpinner className="animate-spin mr-2 my-1" />
                                </button>
                            ) : (
                                <button onClick={enviarArquivos} className="botao_1">
                                    Enviar
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    )
}

export default WizzardLivro