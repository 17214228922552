import { CircularProgress} from '@mui/material'
import { useState } from 'react'
import { FaCircle } from 'react-icons/fa'
import { FaCircleCheck } from 'react-icons/fa6'
import { PiTrash } from 'react-icons/pi'

const Validacao = ({ view, setModalValidar, setLinhaSelecionada, linhaSelecionada, dadosValidar, getDados, setProblemModal }) => {
    const token = localStorage.getItem('key')
    const [loading, setLoading] = useState(true)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [erros, setErros] = useState([])
    const [modalDesvalida, setModalDesvalida] = useState(false)
    const [dados, setDados] = useState({
        transcricao: linhaSelecionada.transcricao
    })

    const handleChange = async (e) => {
        const { name, value } = e.target
        setDados((prevDados) => ({
            ...prevDados,
            [name]: value,
        }))
        setLoadingUpdate(true)
        try {
            await fetch(`https://transcritor-ia.com:8086/api/livro/${linhaSelecionada.id_linha}/editar-transcricao/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    transcricao: value,
                }),
            })

            getDados()
            setLoadingUpdate(false)
        } catch (error) {
            setProblemModal(true)
            setErros(['Erro de rede ou servidor.'])
        }
    }

    const deletarLinhaApi = async (linhaId) => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/linha/${linhaId}/`, {
                method: 'DELETE',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                },
            })

            if (response.ok) {
                fechar()
                getDados()
            } else {
                console.error(`Erro ao deletar a linha ${linhaId}:`, response.status, await response.text())
            }
        } catch (error) {
            setProblemModal(true)
            console.error(`Erro na requisição de deletar linha ${linhaId}:`, error)
        }
    }

    const fechar = () => {
        setModalValidar()
        setLinhaSelecionada(null)
    }

    const certificarLinha = async (idLinha) => {
        try {
            await fetch(`https://transcritor-ia.com:8086/api/livro/${idLinha}/certificar-linha/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            })
            getDados()
            linhaSelecionada.certificada = true
        } catch (error) {
            console.error('Erro ao certificar a linha:', error)
        }
    }

    const retranscreverLinha = async (idLinha) => {
        try {
            await fetch(`https://transcritor-ia.com:8086/api/livro/${idLinha}/retranscrever/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            })
            getDados()
            linhaSelecionada.certificada = false
        } catch (error) {
            console.error('Erro ao retranscrever a linha:', error)
        } finally {
            setModalDesvalida(false)
        }
    }

    return (
        <main className="absolute h-full w-full flex items-center justify-center z-30">
            <div onClick={() => fechar()} className="fundo-desfocado"></div>
            { modalDesvalida &&
                <div className="absolute h-full w-full flex items-center justify-center z-30">
                    <div onClick={() => fechar()} className="fundo-desfocado"></div>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-lg sombra z-40">
                        <p className="mt-2 text-center">Tem certeza que deseja desvalidar essa linha? <br/> Dados de transcricao podem ser perdidos</p>
                        <div className="flex items-center justify-center w-full gap-4 mt-4">
                            <button className='botao_1' onClick={() => setModalDesvalida(false)}>Cancelar</button>
                            <button className='botao_1_alt' onClick={() => retranscreverLinha(linhaSelecionada.id_linha)}>Desvalidar</button>
                        </div>
                    </div>
                </div>
            }
            <div className="flex flex-col rounded-lg sombra  border-2 border-black bg-white max-w-[90%] max-h-[95%] overflow-auto z-20 shadow-lg">
                
                <div className="flex justify-between p-2 w-full overflow-hidden">
                    <div className="flex gap-2 w-[80%] ">
                        <h1 className="font-semibold">Página: {dadosValidar.numeroPagina} |</h1>
                        <h1 className="font-semibold">Região: {dadosValidar.numeroRegiao} |</h1>
                        <h1 className="font-semibold">Linha: {dadosValidar.numeroLinha}</h1>
                    </div>

                    {!view &&
                        <button className="botao-container -mr-3" onClick={() => deletarLinhaApi(linhaSelecionada.id_linha)}>
                            <PiTrash size={30}/>
                        </button>
                    }
                </div>

                <div className="p-4 space-y-4 flex-1 overflow-auto">
                    <div className="bg-gray-100 h-36 flex items-center justify-center p-4 rounded-md shadow-md">
                        {loading && <CircularProgress size={30} color="inherit" />}
                        <img
                            src={`https://transcritor-ia.com:8086${linhaSelecionada.linha}?t=${new Date().getTime()}`}
                            onLoad={() => setLoading(false)}
                            style={{ display: loading ? 'none' : 'block' }}
                            alt="Imagem carregada"
                        />
                    </div>

                    <div className='relative'>
                        <input
                            className="input_3"
                            placeholder='Transcrição para editar'
                            value={dados.transcricao}
                            name="transcricao"
                            onChange={handleChange}
                        />
                        {linhaSelecionada.certificada ? <FaCircleCheck size={20} onClick={() => setModalDesvalida(true)} className='absolute -top-3 -end-3 cursor-pointer' /> : loadingUpdate ? <CircularProgress size={20} color="inherit" className='absolute -top-3 -end-3' /> : <FaCircle size={20} onClick={() => certificarLinha(linhaSelecionada.id_linha)} className='absolute -top-3 -end-3 cursor-pointer' />}
                    </div>

                    {erros.length > 0 && (
                        <div className="text-red-500 font-bold">
                            {erros.map((erro, index) => (
                                <p key={index}>{erro}</p>
                            ))}
                        </div>
                    )}
                </div>

            </div>
        </main>
    )
}

export default Validacao
