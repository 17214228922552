import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  //clent id da aplicação, qualquer duvida falar com alex, ele tem o acesso do painel do google
  <GoogleOAuthProvider clientId="67106670715-befpqpmjlotrepqpj03hq91gt4qf34af.apps.googleusercontent.com">
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>
)