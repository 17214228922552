import { useEffect } from "react"

export const useAtalho = (atalho, callback) => {
    const handleKeyDown = (event) => {
        const target = event.target
        const isTyping = 
            target.tagName === 'INPUT' || 
            target.tagName === 'TEXTAREA' || 
            target.isContentEditable

        if (!isTyping && atalho === event.key) {
            return callback(event)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    })
}
