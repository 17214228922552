import React from 'react'
import { Modal, Box, Button, CircularProgress } from '@mui/material'

const ImportModal = ({ aberto, aoFechar, aoMudarArquivo, aoImportar, carregandoImportacao }) => {
    return (
        <Modal open={aberto} onClose={aoFechar} label='ImportModal'>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2
            }}>
                <input label="inputArquivoTranskribus" type="file" accept=".zip" onChange={aoMudarArquivo} style={{ marginTop: 16, marginBottom: 16 }} />
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Button onClick={aoImportar} disabled={carregandoImportacao} variant="contained" color="primary">
                        {carregandoImportacao ? <CircularProgress size={24} /> : 'Importar'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default ImportModal