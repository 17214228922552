import React, { useEffect, useState } from 'react'
import DropDown from '../drop_down'
import NovoEscrevente from '../modais/modal_novo_escrevente'
import NovoTipoLivro from '../modais/modal_novo_tipo_livro'
import { Button, CircularProgress } from '@mui/material'

const EditarLivro = ({ setEditando, setModalOptions, getLivros, idLivro }) => {
    const token = localStorage.getItem('key')
    const [escreventes, setEscreventes] = useState([])
    const [tipoDocumento, setTipoDocumento] = useState([])
    const [modalEscrevente, setModalEscrevente] = useState(false)
    const [modalTipoLivro, setModalTipoLivro] = useState(false)
    const [carregando, setCarregando] = useState(false)
    const [dadosLivro, setDadosLivro] = useState({
        nome: "",
        local: "",
        periodo_inicial: "",
        periodo_final: "",
        id_tipo_documento: "",
        id_escrevente: "",
    })

    const atualizarLivro = async () => {
        let livroForm = { ...dadosLivro }

        // Filtrar campos preenchidos
        Object.keys(livroForm).forEach((key) => {
            if (!livroForm[key] && livroForm[key] !== 0) {
                delete livroForm[key]
            }
        })

        try {
            setCarregando(true)
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${idLivro}/`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(livroForm),
            })

            if (response.ok) {
                getLivros()
                setEditando(false)
                setCarregando(false)
            } else {
                console.error('Erro ao atualizar livro:', response.status, await response.text())
            }
        } catch (error) {
            console.error('Erro na requisição:', error)
        }
    }

    const getEscrivaos = async () => {
        const response = await fetch('https://transcritor-ia.com:8086/api/escrevente/', {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`,
            },
        })

        if (response.ok) {
            const resposta = await response.json()
            setEscreventes(resposta)
        }
    }

    const getTipoDoc = async () => {
        const response = await fetch('https://transcritor-ia.com:8086/api/tipo-documento/', {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`,
            },
        })

        if (response.ok) {
            const resposta = await response.json()
            setTipoDocumento(resposta)
        }
    }

    const getLivroDetails = async () => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${idLivro}/`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                },
            })
    
            if (response.ok) {
                const data = await response.json()
                
                const tipoDocumentoId = tipoDocumento.find(doc => doc.nome === data.tipo_documento)?.id_tipo_documento || ""
                const escreventeId = escreventes.find(esc => esc.nome === data.escrevente)?.id_escrevente || ""
    
                setDadosLivro({
                    nome: data.nome || "",
                    local: data.local || "",
                    periodo_inicial: data.periodo_inicial || "",
                    periodo_final: data.periodo_final || "",
                    id_tipo_documento: tipoDocumentoId,
                    id_escrevente: escreventeId,
                })
            } else {
                console.error('Erro ao buscar detalhes do livro:', response.status, await response.text())
            }
        } catch (error) {
            console.error('Erro na requisição:', error)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getEscrivaos()
            await getTipoDoc()
        }
    
        fetchData()
        setModalOptions(false)
        // eslint-disable-next-line
    }, [])
    
    useEffect(() => {
        if (tipoDocumento.length > 0 && escreventes.length > 0) {
            getLivroDetails()
        }
        // eslint-disable-next-line
    }, [tipoDocumento, escreventes])
    

    return (
        <div className="absolute h-full w-full flex items-center justify-center z-50">
            <div onClick={() => { setEditando(false) }} className="fundo-desfocado z-10"></div>
            {modalEscrevente && (
                <NovoEscrevente
                    setModal={setModalEscrevente}
                    receberEscreventes={getEscrivaos}
                />
            )}
            {modalTipoLivro && (
                <NovoTipoLivro
                    setModal={setModalTipoLivro}
                    receberEscreventes={getTipoDoc}
                />
            )}
            <div className="flex flex-col items-center rounded-lg sombra bg-white min-w-[400px] w-[30%] max-h-[95%] z-20 overflow-auto">
                <div className="flex flex-col items-center justify-center bg-white w-full py-5 mb-2">
                    <div className="flex flex-col gap-2 w-[90%]">
                        <h1>Nome</h1>
                        <input
                            value={dadosLivro.nome}
                            onChange={(e) => setDadosLivro({ ...dadosLivro, nome: e.target.value })}
                            type="text"
                            className="input_2"
                        />

                        <h1>Local</h1>
                        <input
                            value={dadosLivro.local}
                            onChange={(e) => setDadosLivro({ ...dadosLivro, local: e.target.value })}
                            type="text"
                            className="input_2"
                        />
                        <div className="flex justify-between gap-5">
                            <div className="flex flex-col w-full">
                                <h1>Periodo Inicial</h1>
                                <input
                                    value={dadosLivro.periodo_inicial}
                                    onChange={(e) => setDadosLivro({ ...dadosLivro, periodo_inicial: e.target.value })}
                                    type="date"
                                    className="input_2"
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <h1>Periodo Final</h1>
                                <input
                                    value={dadosLivro.periodo_final}
                                    onChange={(e) => setDadosLivro({ ...dadosLivro, periodo_final: e.target.value })}
                                    type="date"
                                    className="input_2"
                                />
                            </div>
                        </div>
                        <h1>Tipo do Documento</h1>
                        <DropDown
                            opcoes={tipoDocumento}
                            chaveId="id_tipo_documento"
                            value={dadosLivro.id_tipo_documento}
                            onChange={(id) => setDadosLivro({ ...dadosLivro, id_tipo_documento: id })}
                            placeholder="Selecione ou busque um tipo de documento"
                            onAdd={() => setModalTipoLivro(true)}
                        />
                        <h1>Escrevente</h1>
                        <DropDown
                            opcoes={escreventes}
                            chaveId="id_escrevente"
                            value={dadosLivro.id_escrevente}
                            onChange={(id) => setDadosLivro({ ...dadosLivro, id_escrevente: id })}
                            placeholder="Selecione ou busque um escrevente"
                            onAdd={() => setModalEscrevente(true)}
                        />
                        <div className="relative flex items-center justify-center w-[100%] mt-3">
                            {carregando ? (
                                <Button variant='contained' sx={{ mt: 1 }} fullWidth> <CircularProgress size={24} color="inherit"/> </Button>      
                            ):( 
                                <Button variant='contained' onClick={() => atualizarLivro()} sx={{ mt: 1 }} fullWidth> Salvar mudanças </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditarLivro