import React, { useState, useEffect } from 'react'
import { FaCircle } from 'react-icons/fa'
import { FaCircleCheck } from 'react-icons/fa6'
import { Box, Typography, IconButton, TextField, useTheme, Modal, CircularProgress } from '@mui/material'
import Botao from '../interface/botão'

const Linha = ({ view, linha, indexLinha, indexRegiao, validarLinha, handleFocarLinha, getDados, setProblemModal }) => {
    const theme = useTheme()
    const token = localStorage.getItem('key')
    const [editando, setEditando] = useState(false)
    const [loading, setLoading] = useState(false)
    const [texto, setTexto] = useState(linha.transcricao)
    const [modalDesvalida, setModalDesvalida] = useState(false)
    
    useEffect(() => {
        setTexto(linha.transcricao)
    }, [linha.transcricao])

    const backgroundColor = linha.focado
        ? theme.palette.custom.focada
        : linha.certificada
        ? theme.palette.custom.certificada
        : 'white' // cor padrão

    const certificarLinha = async (idLinha) => {
        try {
            setLoading(true)
            await fetch(`https://transcritor-ia.com:8086/api/livro/${idLinha}/certificar-linha/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            })
            getDados()
        } catch (error) {
            setProblemModal(true)
            console.error('Erro ao certificar a linha:', error)
        }finally{
            setLoading(false)
        }
    }

    const retranscreverLinha = async (idLinha) => {
        try {
            await fetch(`https://transcritor-ia.com:8086/api/livro/${idLinha}/retranscrever/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            })
            getDados()
            setModalDesvalida(false)
        } catch (error) {
            setProblemModal(true)
            console.error('Erro ao retranscrever a linha:', error)
        }
    }

    const handleEditChange = async (e) => {
        const value = e.target.value
        setTexto(value)

        if(e.key === 'Enter'){
            try {
                await fetch(`https://transcritor-ia.com:8086/api/livro/${linha.id_linha}/editar-transcricao/`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        transcricao: value,
                    }),
                })
                getDados()
                console.log('enter')
            } catch (error) {
                setProblemModal(true)
                console.error('Erro ao editar a transcrição:', error)
            }
        }

    }

    return (
        <Box key={linha.id_linha + indexRegiao * 2} display="flex" mx={2} gap={2} alignItems="center">
            <Modal
                open={modalDesvalida}
                aria-labelledby="confirm-edit-title"
                aria-describedby="confirm-edit-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 550, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Typography id="confirm-edit-title" variant="h6" component="h2">
                        Tem certeza que deseja desvalidar essa linha?
                    </Typography>
                    <Typography id="confirm-edit-description" sx={{ mt: 2 }}>
                        Dados de transcricao podem ser perdidos.
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Botao variant="default" onClick={() => setModalDesvalida(false)}>
                            Cancelar
                        </Botao>
                        <Botao variant="default_negativo" onClick={() => retranscreverLinha(linha.id_linha)}>
                            Desvalidar
                        </Botao>
                    </Box>
                </Box>
            </Modal>
            <Typography
                variant="h6"
                sx={{
                    color: 'black',
                    ml: indexLinha > 8 ? -0.8 : 0,
                    mr: indexLinha > 8 ? -.5 : 0,
                }}
            >
                {indexLinha + 1}
            </Typography>
            <Box
                key={indexLinha}
                sx={{ backgroundColor }}
                display="flex"
                px={2}
                width={'100%'}
                alignItems="center"
                justifyContent="center"
                mt={3}
                className="cursor-pointer rounded-lg"
                onClick={() => {
                    handleFocarLinha(linha.id_linha, true)
                    setEditando(true)
                    setTexto(linha.transcricao)
                }}
            >
                {editando ? (
                    <TextField
                        fullWidth
                        value={texto}
                        onChange={view ? null : handleEditChange}
                        onDoubleClick={() => validarLinha(linha, indexLinha, indexRegiao)}
                        onBlur={() => setEditando(false)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                handleEditChange(e)
                                setEditando(false)
                            }
                        }}
                        autoFocus
                        variant="standard"
                        multiline
                    />
                ) : (
                    <Typography
                        onDoubleClick={() => validarLinha(linha, indexLinha, indexRegiao)}
                        className="w-full text-start"
                    >
                        {linha.transcricao || `Linha ${indexLinha + 1}`}
                    </Typography>
                )}
                {view === false && (
                    loading ? (
                        <IconButton className="ml-2">
                            <CircularProgress size={20} />
                        </IconButton>
                    ):(
                        linha.processando ? (
                            /*<img alt='animate' className='h-6 w-6 bg-black rounded-full ml-2 mr-2 my-2' src='/imagens/magic_animation.webp'></img>*/
                            <IconButton onClick={() => setModalDesvalida(true)} className="ml-2">
                                <CircularProgress size={20} />
                            </IconButton>
                        ) : linha.certificada ? (
                            <IconButton onClick={() => setModalDesvalida(true)} className="ml-2">
                                <FaCircleCheck />
                            </IconButton>
                        ) : (
                            <IconButton onClick={() => certificarLinha(linha.id_linha)} className="ml-2">
                                <FaCircle />
                            </IconButton>
                        )
                    )
                )}
            </Box>
        </Box>
    )
}

export default Linha