import React, { useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useLocation, useNavigate } from 'react-router-dom'
import { Checkbox } from '@mui/material'

const Imagem = ({ nome, numero, box, id_imagem, miniatura, setIdSelecao, idSelecao, total }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id_imagem })
    const url = useLocation()
    const navigate = useNavigate()
    const [isDragging, setIsDragging] = useState(false)
    const [startX, setStartX] = useState(0)
    const [startY, setStartY] = useState(0)

    const toggleSelection = (e) => {
        e.stopPropagation()
        if (idSelecao.includes(id_imagem)) {
            setIdSelecao(idSelecao.filter(id => id !== id_imagem))
        } else {
            setIdSelecao([...idSelecao, id_imagem])
        }
    }

    const handleMouseDown = (e) => {
        // Ignora se o clique foi na Checkbox
        if (e.target.closest('.MuiCheckbox-root')) return
        setStartX(e.clientX)
        setStartY(e.clientY)
        setIsDragging(false)
    }

    const handleMouseMove = (e) => {
        // Ignora se o clique foi na Checkbox
        if (e.target.closest('.MuiCheckbox-root')) return
        const dx = Math.abs(e.clientX - startX)
        const dy = Math.abs(e.clientY - startY)
        if (dx > 5 || dy > 5) {
            setIsDragging(true)
        }
    }

    const handleMouseUp = (e) => {
        // Não faz nada se for arrasto ou clique na Checkbox
        if (isDragging || e.target.closest('.MuiCheckbox-root')) return
        navigate(`${url.pathname}/imagem/${numero}/${total}/${id_imagem}`)
    }
    
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <div
            className="relative flex flex-col cursor-pointer"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        >
            <div
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
            >
                {miniatura ? (
                    <div className={`relative`}>
                        <div className='h-[12px] w-full bg-[#655a59] rounded-t-lg'/>
                        <div className={`absolute top-4 text-center start-1 bg-white rounded-full w-[25px]`}>
                            <span className={`-mt-1 text-sm`}>{numero}</span>
                        </div>
                        <img
                            title={`${nome}`}
                            className='rounded-b-lg'
                            src={`https://transcritor-ia.com:8086${miniatura}?t=${new Date().getTime()}`}
                            alt={`Miniatura Imagem ${nome}`}
                        />
                        {box &&
                            <div className='absolute bottom-0 z-10'>
                                <div className='absolute mt-3 ml-3 rounded-sm bg-white w-4 h-4'/>
                                    <Checkbox   
                                        checked={idSelecao.includes(id_imagem)}
                                        onChange={toggleSelection}
                                        onClick={(e) => e.stopPropagation()}
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onMouseUp={(e) => e.stopPropagation()}
                                        inputProps={{ 'aria-label': `Select Imagem ${numero}` }}
                                    />
                            </div>
                        }
                    </div>
                ) : (
                    <div className='flex items-center justify-between bg-[#b3b7c2] font-semibold text-lg p-4'>
                        <span>Imagem {nome}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Imagem