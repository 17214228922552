import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ModalPortal from './modais/modal_portal'
import EditarLivro from './modais/modal_editar_livro'
import { BsFillBookmarkStarFill } from 'react-icons/bs'
import { Box, Typography, TextField, List, ListItem, Switch, Modal, Fade, Backdrop, MenuItem, Select, Divider, Button, CircularProgress } from '@mui/material'
import { PiDotsThreeVerticalBold } from 'react-icons/pi'
import { isBrowser } from 'react-device-detect'

const formatUrl = (text) => (text ? text.replace(/\s+/g, '-') : '')

const formatDateBR = (date) => {
  if (!date) return ''
  const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
}

const Livro = ({ id, livroCompartilhado = false, dono = '', nome = '', local = '', periodoInicio = '', periodoFim = '', escrivao = '', transcricao_publica = false, capa = '', getLivros, page }) => {
  const token = localStorage.getItem('key') || ''
  const nomeUrl = formatUrl(nome)
  const periodoInicioBR = formatDateBR(periodoInicio)
  const periodoFimBR = formatDateBR(periodoFim)
  const [modalAdd, setModalAdd] = useState(false)
  const [modalOptions, setModalOptions] = useState(false)
  const [email, setEmail] = useState('')
  const [usuarios, setUsuarios] = useState([])
  const [usuariosCompartilhados, setUsuariosCompartilhados] = useState([])
  const [isTranscricaoPublica, setIsTranscricaoPublica] = useState(transcricao_publica)
  const [confirma, setConfirma] = useState('')
  const [deletando, setDeletando] = useState(false)
  const [editando, setEditando] = useState(false)
  
  useEffect(() => {
    if (!email.trim()) {
      setUsuarios([])
      return
    }

    const timeoutId = setTimeout(() => {
      fetch(`https://transcritor-ia.com:8086/api/usuario/listar_usuarios/?email=${email}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUsuarios(Array.isArray(data) ? data : data ? [data] : [])
        })
        .catch((error) => {
          console.error('Erro ao buscar usuários:', error)
        })
    }, 300)

    return () => clearTimeout(timeoutId)
  }, [email, token])

  const compartilharLivro = (email) => {
    if (!email) return
    fetch(`https://transcritor-ia.com:8086/api/livro/${id}/compartilhar/`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ email }),
    })
      .catch((error) => {
        console.error('Erro ao compartilhar livro:', error)
      })
      .finally(() => {
        buscarCompartilhamentos()
      })
  }

  const buscarCompartilhamentos = () => {
      fetch(`https://transcritor-ia.com:8086/api/livro/${id}/compartilhamento/`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        const sortedData = Array.isArray(data) ? data.sort((a, b) => a.usuario.username.localeCompare(b.usuario.username)) : []
        setUsuariosCompartilhados(sortedData)
      })
      .catch((error) => {
        console.error('Erro ao buscar compartilhamentos:', error)
      })
  }

  const removerCompartilhamento = (usuarioId) => {
    if (!usuarioId) return
    fetch(`https://transcritor-ia.com:8086/api/livro/${id}/compartilhamento/${usuarioId}/`, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          setUsuariosCompartilhados((prev) =>
            prev.filter((usuarioCompartilhado) => usuarioCompartilhado.usuario.id !== usuarioId)
          )
        } else {
          console.error('Erro ao remover compartilhamento')
        }
      })
      .catch((error) => {
        console.error('Erro ao remover compartilhamento:', error)
      })
  }

  const alterarEstadoLivro = (novoEstado) => {
    fetch(`https://transcritor-ia.com:8086/api/livro/${id}/`, {
      method: 'PATCH',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ 
        transcricao_publica: novoEstado,
        publico: novoEstado,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setIsTranscricaoPublica(novoEstado)
        } else {
          console.error('Erro ao alterar estado do livro')
        }
      })
      .catch((error) => {
        console.error('Erro ao alterar estado do livro:', error)
      })
  }

  const atualizarFuncao = (usuarioId, novaFuncao) => {
    fetch(`https://transcritor-ia.com:8086/api/livro/${id}/compartilhamento/${usuarioId}/`, {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
      body: JSON.stringify({ funcao: novaFuncao }),
    })
    .then((response) => {
      if (response.ok) {
        buscarCompartilhamentos()
      }
    })
    .catch((error) => console.error('Erro ao atualizar função:', error))
  }

  const handleImageUpload = (event) => {
    console.log('event', event)
    const file = event.target.files[0]
    if (!file) return
  
    // Função para comprimir a imagem para WEBP com qualidade de 50%
    const comprimirImagem = (arquivo, qualidade) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          const img = new Image()
          img.src = e.target.result
          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
  
            // Define o tamanho do canvas igual ao da imagem original
            canvas.width = img.width
            canvas.height = img.height
            ctx.drawImage(img, 0, 0)
  
            // Converte a imagem para WEBP
            canvas.toBlob(
              (blob) => {
                if (blob) resolve(blob)
                else reject(new Error('Falha ao processar a imagem'))
              },
              'image/webp',
              qualidade
            )
          }
          img.onerror = reject
        }
        reader.onerror = reject
        reader.readAsDataURL(arquivo)
      })
  
    comprimirImagem(file, 0.1) // Qualidade de 10%
      .then((blobComprimido) => {
        const formData = new FormData()
        formData.append('imagem', blobComprimido, `${file.name.split('.')[0]}.webp`)
  
        fetch(`https://transcritor-ia.com:8086/api/livro/${id}/atualizar-capa/`, {
          method: 'PUT',
          headers: {
            Authorization: `Token ${token}`,
          },
          body: formData,
        })
          .then((response) => {
            if (response.ok) {
              console.log('Imagem enviada com sucesso')
              getLivros()
            }
          })
          .catch((error) => {
            console.error('Erro ao enviar imagem:', error)
          })
      })
      .catch((error) => {
        console.error('Erro ao processar imagem:', error)
      })
  }

  const deletarLivro = () => {
    setDeletando(true)
    fetch(`https://transcritor-ia.com:8086/api/livro/${id}/`, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          getLivros()
        } else {
          console.error('Erro ao deletar livro')
        }
        setDeletando(false)
      })
      .catch((error) => {
        console.error('Erro ao deletar livro:', error)
        setDeletando(false)
      })
  }
  
  return (
      <Box sx={{ width: 300,  position: 'relative', bgcolor: 'white', display: 'flex', flexDirection: 'column', boxShadow: '-8px 8px 10px rgba(0, 0, 0, 0.7)', cursor: 'pointer', borderRadius: 3 }}>
        <div className='absolute mt-[2px] rounded-xl -z-[1] w-full h-full bg-gray-300'/>
        {deletando && (
          <ModalPortal>
              <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
                  <CircularProgress size={50} color="inherit" />
              </div>
          </ModalPortal>
        )}
        {editando && (
          <ModalPortal>
            <EditarLivro setEditando={setEditando} setModalOptions={setModalOptions} getLivros={getLivros} idLivro={id}/>
          </ModalPortal>
        )}
        {modalAdd && (
          <ModalPortal>
              <Modal
                open={modalAdd}
                onClose={() => {
                  setModalAdd(false)
                  setEmail('')
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={modalAdd}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh',  }}
                  >
                      <Box
                          sx={{ bgcolor: 'white', p: 3, borderRadius: 2, boxShadow: 3, zIndex: 1400, width: '600px', maxHeight: '80%', overflowY: 'auto', }}
                      >
                          <Typography variant="h6" gutterBottom>
                            Adicionar editores
                          </Typography>
                          <TextField
                              fullWidth
                              variant="outlined"
                              placeholder="Digite o email do usuário"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                          />
                          <List>
                              {usuarios.map((usuario, index) => (
                              <ListItem
                                  key={index}
                                  button
                                  onClick={() => compartilharLivro(usuario.email)}
                                  sx={{ borderBottom: '1px solid #ccc' }}
                              >
                                  <Box>
                                    <Typography variant="body1">{`${ usuario?.username.replace(/_/g, ' ') || 'Desconhecido' }`}</Typography>
                                    <Typography variant="body2" color="text.secondary">{usuario?.email}</Typography>
                                  </Box>
                              </ListItem>
                              ))}
                              {usuariosCompartilhados.length > 0 && (
                              <>
                                  <Typography variant="subtitle1" mt={2} gutterBottom>
                                  Pessoas com acesso:
                                  </Typography>
                                  {usuariosCompartilhados.map((usuarioCompartilhado, index) => (
                                  <ListItem
                                      key={index}
                                      sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }}
                                  >
                                      <Box>
                                        <Typography variant="body1">{`${ usuarioCompartilhado.usuario?.username.replace(/_/g, ' ') || 'Desconhecido' }`}</Typography>
                                        <Typography variant="body2" color="text.secondary">{usuarioCompartilhado.usuario?.email}</Typography>
                                      </Box>
                                      <Select
                                        value={usuarioCompartilhado.funcao || 'transcritor'}
                                        onChange={(e) => {
                                          const novaFuncao = e.target.value
                                          atualizarFuncao(usuarioCompartilhado.usuario?.id, novaFuncao)
                                        }}                                      
                                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                      >
                                        <MenuItem value="visualizador">Visualizador</MenuItem>
                                        <MenuItem value="transcritor">Transcritor</MenuItem>
                                        <Divider />
                                        <MenuItem onClick={() => removerCompartilhamento(usuarioCompartilhado.usuario?.id)} value="remover">
                                          Remover Acesso
                                        </MenuItem>
                                      </Select>
                                  </ListItem>
                                  ))}
                              </>
                              )}
                          </List>
                          <Typography variant="h6" gutterBottom>Acesso geral</Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center'}}>
                              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                                  {isTranscricaoPublica ? 'Público' : 'Privado'}
                              </Typography>
                              <Switch
                                  checked={isTranscricaoPublica}
                                  onChange={() => alterarEstadoLivro(!isTranscricaoPublica)}
                                  color="primary"
                              />
                          </Box>
                      </Box>
                  </Box>
                </Fade>
              </Modal>
          </ModalPortal>
        )}
        {modalOptions && (
          <ModalPortal>
            <Modal
              open={modalOptions}
              onClose={() => setModalOptions(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={modalOptions}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
                >
                  <Box
                    sx={{ bgcolor: 'white', p: 3, borderRadius: 2, boxShadow: 3, zIndex: 1400, width: '500px', maxHeight: '80%', overflowY: 'auto' }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        setModalAdd(true); buscarCompartilhamentos()
                      }}
                      sx={{ mb: 2 }}
                    >
                      Compartilhar Livro
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        setEditando(true)
                      }}
                      sx={{ mb: 2 }}
                    >
                      Editar dados do Livro
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        document.getElementById(`upload-imagem-${id}`).click()
                        setModalOptions(false)
                      }}
                      sx={{ mb: 2 }}
                    >
                      Enviar capa
                    </Button>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={`Digite '${nome}' para habilitar o botão`}
                      value={confirma}
                      onChange={(e) => setConfirma(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if(confirma.toLowerCase() === `${nome.toLowerCase()}`){
                              deletarLivro()
                            }
                        }
                      }}
                      sx={{ mb: 1 }}
                    />
                    <Button
                      disabled={confirma.toLowerCase() !== `${nome.toLowerCase()}`}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => {
                        deletarLivro()
                        setModalOptions(false)
                      }}
                    >
                      Deletar Livro
                    </Button>
                  </Box>
                </Box>
              </Fade>
            </Modal>
          </ModalPortal>
        )}
        {(livroCompartilhado && dono)&& 
          <BsFillBookmarkStarFill
            color='#e5e7eb'
            title={`Compartilhado por ${dono || 'Desconhecido'}`}
            size={25}
            style={{ position: 'absolute', top: -2, right: 3 }}
          />
        }
        <input
          id={`upload-imagem-${id}`}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
        <button
          id={`elemento-botao-${id}`}
          onClick={() => setModalOptions(true)}
          style={{ display: 'none' }}
        />
        <Link
          to={{
            pathname: livroCompartilhado
              ? `/coleções/livro/${dono ? dono : 'publico'}/${id}/${nomeUrl}`
              : `/coleções/livro/${id}/${nomeUrl}`,
          }}
          state={{ page }}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {!capa ? 
            <Box sx={{ height: 300, width: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#b3b7c2', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
              <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                Capa do livro
              </Typography>
            </Box>
          :
            <Box sx={{ height: 300, width: 300, overflow: 'hidden', display: 'flex', justifyContent: 'center', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
              <img src={`https://transcritor-ia.com:8086${capa}?t=${new Date().getTime()}`} alt="Capa do livro" />
            </Box>      
          }
          <Box sx={{ p: 1 }}>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography noWrap variant={isBrowser ? 'body2' : 'h4'}>
                <strong>Livro:</strong> <span>{nome || '-'}</span>
              </Typography>
              {!livroCompartilhado &&
                <Box>
                  <label htmlFor={`elemento-botao-${id}`} onClick={(e) => { e.stopPropagation(); }}>
                    <PiDotsThreeVerticalBold size={isBrowser ? 25 : 45} style={{ cursor: 'pointer', marginTop: -5, marginRight: -5 }} onClick={(e) => { e.stopPropagation(); }} />
                  </label>
                </Box>
              }
            </Box>
            <Typography noWrap variant={isBrowser ? 'body2' : 'h5'}>
              <strong>Local:</strong> <span>{local || '-'}</span>
            </Typography>
            <Typography noWrap variant={isBrowser ? 'body2' : 'h5'}>
              <strong>Período:</strong> <span>{periodoInicioBR || '-'} - {periodoFimBR || '-'}</span>
            </Typography>
            <Typography noWrap variant={isBrowser ? 'body2' : 'h5'}>
              <strong>Escrevente:</strong> <span>{escrivao || '-'}</span>
            </Typography>
          </Box>
        </Link>
      </Box>
  )
}

export default Livro