export const handleMouseDown = (event, stageRef, setMovendo, setLastMousePosition, modo, handleCriacaoAreaStart, handleDesenhoStart, handleSelecionarLinha, handleSelecionarRegiao) => {
    const pos = stageRef.current.getPointerPosition()
    const posInt = { x: Math.round(pos.x), y: Math.round(pos.y) }
    if (event.evt.button === 2) {
        setMovendo(true)
        setLastMousePosition(pos)
    } else{
        handleSelecionarLinha(pos)
        handleSelecionarRegiao(pos)
        if (modo === 'area') {
            handleCriacaoAreaStart(posInt)
        } else if (modo === 'desenho') {
            handleDesenhoStart(posInt)
        }
    } 
}

let scrollInterval = null

export const handleMouseMove = (stageRef, movendo, lastMousePosition, setStage, setLastMousePosition, modo, handleCriacaoAreaMove, handleDesenho, linhaSelecionada, regiaoSelecionada, handleModificacaoLinha, handleModificacaoRegiao, view) => {
    const pos = stageRef.current.getPointerPosition()
    const posInt = { x: Math.round(pos.x), y: Math.round(pos.y) }
    const canvasWidth = window.innerWidth * 0.7
    const scrollSpeed = 10

    const start = () => {
        if (!scrollInterval) {
            scrollInterval = requestAnimationFrame(scroll)
        }
    }

    const stop = () => {
        if (scrollInterval) {
            cancelAnimationFrame(scrollInterval)
            scrollInterval = null
        }
    }

    const scroll = () => {
        setStage((prevStage) => ({
            ...prevStage,
            x: posInt.x < 20 ? prevStage.x + scrollSpeed :
            posInt.x > canvasWidth - 20 ? prevStage.x - scrollSpeed : prevStage.x,
            y: posInt.y < 20 ? prevStage.y + scrollSpeed :
            posInt.y > window.innerHeight - 20 ? prevStage.y - scrollSpeed : prevStage.y,
        }))
        scrollInterval = requestAnimationFrame(scroll)
    }

    if (movendo && lastMousePosition) {
        const dx = posInt.x - lastMousePosition.x
        const dy = posInt.y - lastMousePosition.y

        setStage((prevStage) => ({
            ...prevStage,
            x: prevStage.x + dx,
            y: prevStage.y + dy,
        }))
        setLastMousePosition(posInt)
        stop()
    } else if ((posInt.x < 20 || posInt.x > canvasWidth - 20 || posInt.y < 20 || posInt.y > window.innerHeight - 20) && (linhaSelecionada || regiaoSelecionada) && !view) {
        start()
    } else {
        stop()
        if (modo === 'area') {
            handleCriacaoAreaMove(posInt)
        } else if (modo === 'desenho') {
            handleDesenho(posInt)
        } else if (linhaSelecionada != null && regiaoSelecionada === null) {
            handleModificacaoLinha(posInt)
        } else if (regiaoSelecionada != null) {
            handleModificacaoRegiao(posInt)
        }
    }
}

export const handleMouseUp = (event, setMovendo, setLastMousePosition, modo, handleCriacaoAreaFim, handleDesenhoFim, linhaSelecionada, editarLinha, setLinhaSelecionada, setPontoSelecionado, regiaoSelecionada, setRegiaoSelecionada, setCantoSelecionado, editarRegiao) => {
    if (event.evt.button === 2) {
        setMovendo(false)
        setLastMousePosition(null)
    } else if (modo === 'area') {
        handleCriacaoAreaFim()
    } else if (modo === 'desenho') {
        handleDesenhoFim()
    } else {
        if (linhaSelecionada && linhaSelecionada.length > 0) {
            let tempLinha = linhaSelecionada
            setLinhaSelecionada(null)
            setPontoSelecionado(null)
            editarLinha(tempLinha[0].id_linha, tempLinha[0].pontos)
        }
        if (regiaoSelecionada) {
            let tempRegiao = regiaoSelecionada
            setRegiaoSelecionada(null)
            setCantoSelecionado(null)
            editarRegiao(tempRegiao)
        }
    }
}

export const transformCoords = (x, y, stageRef) => {
    const stage = stageRef.current
    const scale = stage.scaleX()
    const stageX = stage.x()
    const stageY = stage.y()

    return {
        x: (x - stageX) / scale,
        y: (y - stageY) / scale
    }
}