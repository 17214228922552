import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Box, Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Modal } from '@mui/material'
import { RotateLeft, RotateRight, Delete } from '@mui/icons-material'
import { PiFilePdf, PiImage, PiFile } from 'react-icons/pi'

const UploadPagina = ({ setModalUpload, idLivro, getPaginas, arquivosSelecionados, checkStatus }) => {
  const token = localStorage.getItem('key')
  const [files, setFiles] = useState(arquivosSelecionados)
  const [arquivoSelecionado, setArquivoSelecionado] = useState(null)
  const [indexSelecionado, setIndexSelecionado] = useState(null)
  const [graus, setGraus] = useState({})
  const [rotacao, setRotacao] = useState(90)
  const [enviando, setEnviando] = useState(false)
  const canvasRef = useRef(null)

  useEffect(() => {
    if (arquivosSelecionados.length > 0) {
      const firstImageIndex = arquivosSelecionados.findIndex((file) => file.type.startsWith('image/'))
      if (firstImageIndex >= 0) {
        const firstImage = arquivosSelecionados[firstImageIndex]
        setArquivoSelecionado(URL.createObjectURL(firstImage))
        setIndexSelecionado(firstImageIndex)
        setGraus((prev) => ({ ...prev, [firstImageIndex]: 0 }))
      }
    }
  }, [arquivosSelecionados])

  const removerArquivo = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)

    const newRotations = { ...graus }
    delete newRotations[index]
    setGraus(newRotations)

    if (indexSelecionado === index) {
      const remainingImageIndex = newFiles.findIndex((file) => file.type.startsWith('image/'))
      setIndexSelecionado(remainingImageIndex)
      setArquivoSelecionado(
        remainingImageIndex >= 0 ? URL.createObjectURL(newFiles[remainingImageIndex]) : null
      )
    }
  }

  const rotacionar = (graus) => {
    if (indexSelecionado !== null) {
      setGraus((prev) => ({
        ...prev,
        [indexSelecionado]: ((prev[indexSelecionado] || 0) + graus + 360) % 360,
      }))
    }
  }

  const renderRotacionado = useCallback(() => {
    const canvas = canvasRef.current
    if (!canvas || !arquivoSelecionado) return
  
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.src = arquivoSelecionado
  
    img.onload = () => {
      const fixedWidth = 600
      const scaleFactor = fixedWidth / img.width
      const scaledHeight = img.height * scaleFactor
  
      canvas.width = fixedWidth
      canvas.height = scaledHeight
      
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.save()
      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate(((graus[indexSelecionado] || 0) * Math.PI) / 180)
      ctx.drawImage(img, -fixedWidth / 2, -scaledHeight / 2, fixedWidth, scaledHeight)
      ctx.restore()
    }
  }, [arquivoSelecionado, graus, indexSelecionado]);

  const enviarArquivos = async () => {
    if (!files.length) {
      alert('Selecione pelo menos um arquivo para enviar.')
      return
    }

    setEnviando(true)

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const formData = new FormData()
      formData.append('arquivos', file)

      try {
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${idLivro}/transcrever/`, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            Authorization: `Token ${token}`,
          },
          body: formData,
        })

        if (!response.ok) {
          console.log(response.json())
          console.error(`Erro ao enviar o arquivo: ${file.name}`)
        }
      } catch (error) {
        console.error(`Erro ao enviar o arquivo ${file.name}:`, error)
      }
    }

    setEnviando(false)
    setModalUpload(false)
    getPaginas(idLivro)
  }

  useEffect(() => {
    renderRotacionado()
  }, [graus, arquivoSelecionado, renderRotacionado])

  const getIcones = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <PiImage style={{ color: 'blue' }} />
      case 'pdf':
        return <PiFilePdf style={{ color: 'red' }} />
      default:
        return <PiFile style={{ color: 'gray' }} />
    }
  }
  
  return (
    <Modal className='flex items-center justify-center h-full w-full' open onClose={() => {setModalUpload(false); checkStatus()}}>
      <Box
        className="flex gap-5"
        sx={{ p: 3, bgcolor: '#f5f5f5', borderRadius: 2, maxHeight: 650, maxWidth: 1200, mx: 'auto', outline: 'none', boxShadow: 24,}}
      >
        { files.length > 0 &&
          <List sx={{ maxHeight: '100%', overflowY: 'auto', bgcolor: '#fff', borderRadius: 1, p: 2, }}>
            {files.map((file, index) => (
              <ListItem
                key={index}
                sx={{ mb: 1, cursor: 'pointer', bgcolor: index === indexSelecionado ? '#f5f5f5' : 'inherit' }}
                onClick={() => {
                  setArquivoSelecionado(URL.createObjectURL(file));
                  setIndexSelecionado(index);
                }}
              >
                <ListItemIcon>{getIcones(file.name)}</ListItemIcon>
                <ListItemText primary={file.name} />
                <IconButton onClick={() => removerArquivo(index)} color="error">
                  <Delete />
                </IconButton>
              </ListItem>
            ))}
          </List>
        }

        <Box className="flex flex-col gap-5">
          {arquivoSelecionado && (
            <Box sx={{ mt: 3 }}>
              {files[indexSelecionado]?.type === 'application/pdf' ? (
                <iframe src={arquivoSelecionado} title="Visualização do arquivo PDF" style={{ width: 600, height: 500 }} />
              ) : (
                <>
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                    <IconButton onClick={() => rotacionar(-rotacao)}>
                      <RotateLeft />
                    </IconButton>

                    <input
                      value={rotacao}
                      onChange={(e) => setRotacao(parseInt(e.target.value, 10) || 0)}
                      style={{
                        width: 50,
                        textAlign: 'center',
                        border: '1px solid #ccc',
                        borderRadius: 4,
                        padding: '4px 8px',
                      }}
                    />

                    <IconButton onClick={() => rotacionar(rotacao)}>
                      <RotateRight />
                    </IconButton>
                  </Box>

                  <canvas ref={canvasRef} style={{ width: 600, height: 400, border: '1px solid #ccc' }} />
                </>
              )}
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mt: 1 }}>
            { files.length > 0 &&
              <Button
                variant="contained"
                color="primary"
                onClick={enviarArquivos}
                disabled={enviando}
                startIcon={enviando && <span className="spinner" />}
              >
                Enviar
              </Button>
            }
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default UploadPagina